import {apiBase} from 'api/base.js';

class NotificationAPI {
  index() {
    return apiBase.get('/admin/notification');
  }

  show(id) {
    return apiBase.get(`/admin/notification/${id}`);
  }

  create(params) {
    return apiBase.post('/admin/notification', params);
  }

  update(id, params) {
    return apiBase.put(`/admin/notification/${id}`, params);
  }

  delete(id) {
    return apiBase.delete(`/admin/notification/${id}`);
  }
}

export const notificationAPI = new NotificationAPI();
