import {apiBase} from 'api/base.js';

class AlertNotificationUserAPI {
  index(farmId) {
    return apiBase.get(`/admin/alert_notification_user?farm_id=${farmId}`);
  }

  show(farmId, id) {
    return apiBase.get(`/admin/alert_notification_user/${id}?farm_id=${farmId}`);
  }

  create(farmId, params) {
    return apiBase.post('/admin/alert_notification_user', params);
  }

  update(id, params) {
    return apiBase.put(`/admin/alert_notification_user/${id}`, params);
  }

  delete(farmId, id) {
    const params = {farmId: farmId};
    return apiBase.delete(`/admin/alert_notification_user/${id}`, params);
  }
}

export const alertNotificationUserAPI = new AlertNotificationUserAPI();
