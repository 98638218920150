import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  inputArea: {
    marginBottom: '30px',
  },
  textField: {
    width: 300,
  },
  sqlField: {
    width: 600,
  },
};

class UClipsInputForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.record);
    this.syncState = props.syncState;

    this.bleIdItems = [
      {columnId: 'bleId1', label: 'センサー番号1'},
      {columnId: 'bleId2', label: 'センサー番号2'},
      {columnId: 'bleId3', label: 'センサー番号3'},
      {columnId: 'bleId4', label: 'センサー番号4'},
      {columnId: 'bleId5', label: 'センサー番号5'}
    ];
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
    this.syncState(key, e.target.value);
  }

  onChangeDate(key, value) {
    this.setState(() => ({[key]: value}));
    this.syncState(key, value);
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '100px'}}>
        <form className={classes.inputArea}>
          <div>
            <TextField
              type="text"
              required={true}
              placeholder=""
              label="設定名"
              value={this.state.name}
              onChange={(e) => this.onChangeInput(e, 'name')}
              margin="none"
              className={classes.textField}
            />
          </div>

          <p>※farm_id の値は数値ではなく <b>:farm_id</b> と記述してください</p>

          <div>
            <TextField
              type="text"
              required={true}
              placeholder="SELECT * FROM cow WHERE farm_id = :farm_id AND active = true"
              label="SQL"
              value={this.state.sql}
              multiline={true}
              rows={14}
              onChange={(e) => this.onChangeInput(e, 'sql')}
              variant="outlined"
              margin="none"
              className={classes.sqlField}
            />
          </div>
        </form>
      </div>
    );
  }
}

UClipsInputForm.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
  disableFeedLogicSwitchingDate: PropTypes.bool
};

const forExport = withStyles(styles)(UClipsInputForm);
export {forExport as UClipsInputForm};
