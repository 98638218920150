import {apiBase} from 'api/base.js';

class CalfDeleteAPI {
  create(params) {
    return apiBase.post('/admin/calf_delete', params);
  }

  candidates(params) {
    return apiBase.post('/admin/calf_delete/candidates', params);
  }
}

export const calfDeleteAPI = new CalfDeleteAPI();
