import {apiBase} from 'api/base.js';

class MasterImportAPI {
  create(params) {
    return apiBase.post('/admin/master_import', params);
  }

  update(params) {
    return apiBase.put('/admin/master_import', params);
  }

  delete(params) {
    return apiBase.delete('/admin/master_import', params);
  }
}

export const masterImportAPI = new MasterImportAPI();
