import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import {farmAPI} from 'api/farm.js';
import {ViewMode} from 'models/view-mode.js';
import {Farm} from 'models/farm.js';
import {DateUtil} from 'utils/date-util.js';

import {ActionTable} from 'components/tables/ActionTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class FarmIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'id', label: 'ID'},
      {id: 'farmName', label: '牧場名'},
      {id: 'farmType', label: '経営形態'},
      {id: 'contractedPlan', label: '契約プラン'},
      {id: 'managementStyle', label: '運用形態'},
      {id: 'createdAtDisplay', label: '作成日'},
      {id: 'cowCount', label: '牛個体数'},
      {id: 'bleIdCount', label: 'センサー数'},
    ];

    this.filterCondition = '';

    this.state = {
      records: [],
      columns: columns,
      showProgress: false,
    };
  }

  componentDidMount() {
    this.index();
  }

  createAction() {
    const params = {
      pathname: '/farm/create',
    };
    this.props.history.push(params);
  }

  updateAction(record) {
    const params = {
      pathname: `/farm/${record.id}/${ViewMode.UPDATE}`,
    };
    this.props.history.push(params);
  }

  deleteAction(record) {
    const params = {
      pathname: `/farm/${record.id}/${ViewMode.DELETE}`,
    };
    this.props.history.push(params);
  }

  index() {
    this.setState({showProgress: true});

    return farmAPI.index().then((res) => {
      const records = res.data.map((r) => {
        const farm = new Farm(r);

        const farmTypes = [];
        if (farm.isDairy()) farmTypes.push('酪農');
        if (farm.isBreeding()) farmTypes.push('繁殖');
        if (farm.isFattening()) farmTypes.push('肥育');

        let managementStyle = '';
        if (farm.isDepositor()) {
          managementStyle = '預託元';
        } else if (farm.isAgent()) {
          managementStyle = '預託先';
        }

        let farmType = farm.data.farmKind;
        if (farmTypes.length > 0) {
          farmType += ` (${farmTypes.join(' ')})`;
        }

        let contractedPlan = farm.data.contractedPlan;
        if (farm.isInactive()) {
          contractedPlan = `${contractedPlan} (利用停止)`;
        } else if (farm.isNonCustomer()) {
          contractedPlan = `${contractedPlan} (顧客以外)`;
        }

        return {
          id: farm.data.id,
          farmName: farm.data.farmName,
          farmType: farmType,
          contractedPlan: contractedPlan,
          managementStyle: managementStyle,
          createdAtDisplay: DateUtil.toYYYYMMDD(r.createdAt),
          cowCount: farm.data.cowCount,
          bleIdCount: farm.data.bleIdCount,
          canUpdate: true,
          canDelete: farm.data.cowCount === 0,
        };
      });

      this.rawRecords = [].concat(records);

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  onChangeFilterCondition(e) {
    this.filterCondition = e.target.value;

    if (this.filterCondition) {
      const records = this.rawRecords.filter((f) => f.farmName.includes(this.filterCondition));
      this.setState(() => ({
        records: records
      }));
    } else {
      const records = [].concat(this.rawRecords);
      this.setState(() => ({
        records: records
      }));
    }
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>牧場</h2>

        <ul className={classes.flexContainer}>
          <li style={{width: 500}}>
            <TextField
              type="text"
              label="牧場名で絞込"
              value={this.filterCondition}
              onChange={(e) => this.onChangeFilterCondition(e)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </li>

          <li style={{marginLeft: 100, marginTop: 8}}>
            <Button variant="contained" color="primary" onClick={() => this.createAction()}>
              登録
            </Button>
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginTop: 20, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <ActionTable
          records={this.state.records}
          columns={this.state.columns}
          updateAction={(record) => this.updateAction(record)}
          deleteAction={(record) => this.deleteAction(record)}
          linkColumnId="farmName"
        />
      </div>
    );
  }
}

FarmIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(FarmIndex));
export {forExport as FarmIndex};
