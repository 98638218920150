import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';

import {sessionState} from 'session/session-state.js';
import {accountAPI} from 'api/account.js';
import {DateUtil} from 'utils/date-util.js';
import {StringUtil} from 'utils/string-util.js';
import {ErrorUtil} from 'utils/error-util.js';
import {ViewMode} from 'models/view-mode.js';
import {Account} from 'models/account.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ReturnButton} from 'components/ReturnButton.js';
import {AccountInput} from 'components/forms/account/AccountInput.js';
import {ReadOnlyText} from 'components/ReadOnlyText.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class AccountEdit extends React.Component {
  constructor(props) {
    super();

    this.farm = sessionState.currentFarm();
    this.roles = Account.availableRoles(this.farm);

    const params = props.match.params;
    this.id = params.id ? parseInt(params.id, 10) : null;
    this.viewMode = new ViewMode(params.action);

    this.title = `アカウント - ${this.viewMode.label}`;
    this.notification = `${this.viewMode.label}が完了しました`;
    this.buttonColor = this.viewMode.isDelete() ? 'secondary' : 'primary';

    this.state = {
      record: {
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        role: 'operator',
        shared: false,
        deleted: false,
      },
      loaded: false,
      showProgress: false,
      showDialog: false,
      submitDisabled: this.viewMode.isDelete()
    };
  }

  static get REGEX_8_ALPHANUMERIC() {
    return /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/;
  }

  componentDidMount() {
    if (this.viewMode.isCreate()) {
      this.setState({
        loaded: true
      });
      return;
    }

    this.show();
  }

  show() {
    this.setState({showProgress: true});

    return accountAPI.show(this.farm.id(), this.id).then((res) => {
      const record = Object.assign({}, res.data);
      record.feedLogicSwitchingDate = DateUtil.toDate(record.feedLogicSwitchingDate);

      this.setState({
        record: record,
        loaded: true,
        showProgress: false
      });
    });
  }

  returnAction() {
    this.props.history.push('/account');
  }

  syncState(key, value) {
    const input = this.state.input || {...this.state.record};
    input[key] = value;
    this.setState({input: input});
  }

  deleteConfirm(emailConfirmed) {
    if (emailConfirmed === this.state.record.email) {
      this.setState({submitDisabled: false});
    } else {
      this.setState({submitDisabled: true});
    }
    this.syncState({emailConfirmed: emailConfirmed});
  }

  validate() {
    const input = this.state.input;
    const errors = [];

    if (!input) {
      errors.push('内容が変更されていません');
      return {valid: false, errors: errors};
    }

    if (!input.name) {
      errors.push('ユーザー名が入力されていません');
    }

    if (this.viewMode.isCreate()) {
      if (!input.email) {
        errors.push('メールアドレスが入力されていません');
      } else if (!StringUtil.isEmail(input.email)) {
        errors.push('メールアドレスの形式が誤っています');
      }

      if (input.password) {
        if (!AccountEdit.REGEX_8_ALPHANUMERIC.test(input.password)) {
          errors.push('パスワードは英数字8文字以上を設定してください');
        }
      } else {
        errors.push('パスワードが入力されていません');
      }

      if (!input.passwordConfirm) {
        errors.push('確認用パスワードが入力されていません');
      }

      if (input.password && input.passwordConfirm) {
        if (input.password !== input.passwordConfirm) {
          errors.push('パスワードと確認用パスワードが一致しません');
        }
      }
    }

    return {valid: errors.length === 0, errors: errors};
  }

  save() {
    const result = this.validate();
    if (!result.valid) {
      this.setState({errorMessage: result.errors.join('\n')});
      return;
    } else {
      this.setState({errorMessage: ''});
    }

    let action;
    if (this.viewMode.isCreate()) {
      action = accountAPI.create(this.farm.id(), this.createInsertParams());
    } else if (this.viewMode.isUpdate()) {
      action = accountAPI.update(this.id, this.createUpdateParams());
    } else if (this.viewMode.isDelete()) {
      action = accountAPI.delete(this.farm.id(), this.id);
    }

    this.setState({showProgress: true});

    action.then((res) => {
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({showDialog: false});
        this.returnAction();
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({errorMessage: errorMessage});
      console.error(errorMessage);
    });
  }

  createInsertParams() {
    const input = this.state.input;
    return {
      farmId: this.farm.id(),
      email: input.email,
      password: input.password,
      name: input.name,
      role: input.role,
      shared: input.shared,
    };
  }

  createUpdateParams() {
    const input = this.state.input;
    return {
      farmId: this.farm.id(),
      name: input.name,
      role: input.role,
      shared: input.shared,
    };
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>{this.title}</h2>

        <ul className={classes.flexContainer}>
          <li>
            <ReturnButton returnAction={() => this.returnAction()} />
          </li>

          <li style={{marginLeft: '500px'}}>
            <Button
              variant="contained"
              color={this.buttonColor}
              onClick={() => this.save()}
              disabled={this.state.showProgress || this.state.submitDisabled}>
              {this.viewMode.label}
            </Button>
          </li>
        </ul>

        <div style={{marginLeft: '40px'}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <FarmSelect changeAction={() => {}} isDisabled={true} />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <DetailForm
          loaded={this.state.loaded}
          record={this.state.record}
          state={this.state}
          viewMode={this.viewMode}
          syncState={this.syncState.bind(this)}
          roles={this.roles}
          deleteConfirm={this.deleteConfirm.bind(this)}
        />
      </div>
    );
  }
}

AccountEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(AccountEdit));
export {forExport as AccountEdit};

const DetailForm = (props) => {
  if (props.loaded) {
    if (props.viewMode.isDelete()) {
      return (
        <DeleteForm
          record={props.record}
          state={props.state}
          deleteConfirm={props.deleteConfirm}
        />
      );
    } else {
      return (
        <AccountInput
          record={props.record}
          syncState={props.syncState}
          roles={props.roles}
          isCreate={props.viewMode.isCreate()}
        />
      );
    }
  } else {
    return null;
  }
};

DetailForm.propTypes = {
  loaded: PropTypes.bool.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
  viewMode: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

const DeleteForm = (props) => {
  const record = props.record;
  const state = props.state;
  const deleteConfirm = props.deleteConfirm;

  return (
    <div style={{marginLeft: '100px'}}>
      <div style={{marginBottom: 30}}>
        <ReadOnlyText
          label="ユーザー名"
          value={record.name}
        />
      </div>
      <div style={{marginBottom: 50}}>
        <ReadOnlyText
          label="メールアドレス"
          value={record.email}
        />
      </div>

      <div>
        <TextField
          type="text" margin="none"
          label="削除するアカウントのメールアドレスの確認"
          value={state.emailConfirmed}
          onChange={(e) => deleteConfirm(e.target.value)}
          placeholder={record.email}
          style={{width: 400}}
        />
      </div>
    </div>
  );
};

DeleteForm.propTypes = {
  record: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  deleteConfirm: PropTypes.func.isRequired,
};
