import React from 'react';
import {makeStyles} from '@material-ui/styles';

const styles = makeStyles({
  errorMessage: {
    color: '#ff573f',
    fontWeight: 'bold',
    whiteSpace: 'pre',
  },
});

export const ErrorMessage = (props) => {
  const classes = styles();
  const errorMessage = props.errorMessage;
  if (errorMessage) {
    return (
      <div className={classes.errorMessage} style={{marginBottom: '20px'}}>
        {errorMessage}
      </div>
    );
  } else {
    return null;
  }
};
