import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import {GlobalCss} from 'components/global-css.js';
import {SelectDialog} from 'components/dialogs/SelectDialog.js';
import {sessionState} from 'session/session-state.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  inputArea: {
    marginBottom: '30px',
  },
  textField: {
    width: 300,
    marginBottom: '20px',
  },
  readonlyText: GlobalCss.readonlyText,
};

class AuthorizedFarmInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.record);
    this.state.showDialog = false;

    this.syncState = props.syncState;
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
    this.syncState(key, e.target.value);
  }

  addFarm() {
    const farmIds = new Set(this.state.farms.map((farm) => farm.farmId));
    const selectableFarms = sessionState.farms().filter((farm) => {
      return !farmIds.has(farm.id());
    });
    const items = selectableFarms.map((farm) => {
      return {
        key: farm.id(),
        label: farm.farmName(),
        notes: farm.data.farmKind,
      };
    });

    this.setState({showDialog: true, selectItems: items});
  }

  onCloseDialog(result = null) {
    this.setState({showDialog: false});

    if (result) {
      const farms = this.state.farms;
      result.forEach((item) => {
        const farm = {
          farmId: item.key,
          farmName: item.label,
          farmKind: item.notes,
          isNew: true,
        };
        farms.push(farm);
      });
      this.setState({farms: farms});
      this.syncState('farms', farms);
    }
  }

  removeFarm(index) {
    const farms = this.state.farms;
    farms.splice(index, 1);
    this.setState({farms: farms});
    this.syncState('farms', farms);
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '100px'}}>
        <InputForm
          classes={classes}
          state={this.state}
          onChangeInput={this.onChangeInput.bind(this)}
          addFarm={this.addFarm.bind(this)}
          removeFarm={this.removeFarm.bind(this)}
          onCloseDialog={this.onCloseDialog.bind(this)}
        />
      </div>
    );
  }
}

AuthorizedFarmInput.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
};

const forExport = withStyles(styles)(AuthorizedFarmInput);
export {forExport as AuthorizedFarmInput};

const InputForm = (props) => {
  return (
    <UpdateForm
      classes={props.classes}
      state={props.state}
      addFarm={props.addFarm}
      removeFarm={props.removeFarm}
      onCloseDialog={props.onCloseDialog}
    />
  );
};

InputForm.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  addFarm: PropTypes.func.isRequired,
  removeFarm: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};

const UpdateForm = (props) => {
  const classes = props.classes;
  const state = props.state;
  const addFarm = props.addFarm;
  const removeFarm = props.removeFarm;
  const onCloseDialog = props.onCloseDialog;

  return (
    <form className={classes.inputArea} autoComplete='off'>
      <div>
        {state.showDialog ?
          <SelectDialog
            title='牧場の選択'
            description='追加する牧場を選択してください。'
            showDialog={state.showDialog}
            items={state.selectItems}
            onClose={onCloseDialog}
          /> : null
        }
      </div>

      <div className={classes.flexContainer} style={{marginBottom: 20}}>
        <div style={{marginRight: 50}}>
          <TextField
            type='email' label='ユーザー名'
            value={state.accountName}
            InputProps={{
              readOnly: true,
            }}
            variant='outlined'
            margin='none'
            className={classes.readonlyText}
            style={{marginRight: 20}}
          />
        </div>

        <div>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={() => addFarm()}
          >
            牧場の追加
          </Button>
        </div>
      </div>

      {state.farms.map((farm, index) => {
        return (
          <div key={index} style={{marginBottom: 20}}>
            <div className={classes.flexContainer}>
              <div style={{marginRight: 30}}>
                <TextField
                  type='text'
                  value={farm.farmName}
                  InputProps={{
                    readOnly: true,
                  }}
                  margin='none'
                  style={{width: 400}}
                />
              </div>

              <div style={{marginRight: 30}}>
                <TextField
                  type='text'
                  value={farm.farmKind}
                  InputProps={{
                    readOnly: true,
                  }}
                  margin='none'
                  style={{width: 80}}
                />
              </div>

              {state.farms.length >= 2 ?
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <IconButton
                    aria-label='delete'
                    color='secondary'
                    size='small'
                    onClick={() => removeFarm(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                : null
              }

              {farm.isNew ?
                <FiberNewIcon style={{color: 'mediumseagreen'}} />
                : null
              }
            </div>
          </div>
        );
      })}
    </form>
  );
};

UpdateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  addFarm: PropTypes.func.isRequired,
  removeFarm: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};
