import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {SessionContext} from 'session/session-context.js';
import {sessionState} from 'session/session-state.js';

import {SimpleTable} from 'components/tables/SimpleTable.js';

const styles = {};

class HomeForm extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'id', label: '牧場ID'},
      {id: 'farmName', label: '牧場名'},
      {id: 'farmKind', label: '牧場種別'},
      {id: 'farmType', label: '経営形態'},
      {id: 'contractedPlan', label: '契約プラン'},
      {id: 'managementStyle', label: '運用形態'},
      {id: 'usageStatus', label: '契約・稼働状況'},
    ];

    const farms = sessionState.farms() || [];
    const records = farms.map((f) => {
      const farmTypes = [];
      if (f.isDairy()) farmTypes.push('酪農');
      if (f.isBreeding()) farmTypes.push('繁殖');
      if (f.isFattening()) farmTypes.push('肥育');

      let managementStyle = '';
      if (f.isDepositor()) {
        managementStyle = '預託元';
      } else if (f.isAgent()) {
        managementStyle = '預託先';
      }

      let usageStatus = '';
      if (f.isInactive()) {
        usageStatus = '利用停止';
      } else if (f.isNonCustomer()) {
        usageStatus = '顧客以外';
      }

      return {
        id: f.data.id,
        farmName: f.data.farmName,
        farmKind: f.data.farmKind,
        farmType: farmTypes.join(' '),
        contractedPlan: f.data.contractedPlan,
        managementStyle: managementStyle,
        usageStatus: usageStatus,
      };
    });

    this.state = {
      columns: columns,
      records: records
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
  }

  onChangeSelect(e, key) {
    this.setState({[key]: e.target.value});
  }

  render() {
    return (
      <SessionContext.Consumer>
        {(context) => (
          <div style={{marginLeft: '20px'}}>
            <h2>ホーム</h2>

            <SimpleTable records={this.state.records} columns={this.state.columns} />
          </div>
        )}
      </SessionContext.Consumer>
    );
  }
}

HomeForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const forExport = withStyles(styles)(HomeForm);
export {forExport as HomeForm};
