import React from 'react';

import {SessionContext} from 'session/session-context.js';

import {Routing} from 'components/Routing.js';

export class App extends React.Component {
  constructor() {
    super();

    this.state = {
      errorMessage: ''
    };

    this.state = {
      authenticated: false,
    };
  }

  syncState(key, value) {
    this.setState(() => ({
      [key]: value
    }));
  }

  render() {
    return (
      <SessionContext.Provider value={this.state}>
        <Routing
          authenticated={this.state.authenticated}
          syncState={this.syncState.bind(this)}
          errorMessage={this.state.errorMessage}
        />
      </SessionContext.Provider>
    );
  }
}
