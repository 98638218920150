import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = {
  stickyHead: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    fontSize: 16,
    whiteSpace: 'nowrap'
  },
  simpleCell: {
    fontSize: 14,
    padding: '5px 0 5px 16px',
    wordBreak: 'break-all'
  },
};

class SimpleTable extends React.Component {
  constructor(props) {
    super();
  }

  static get propTypes() {
    return {
      classes: PropTypes.object.isRequired,
      records: PropTypes.array,
      columns: PropTypes.array.isRequired,
      height: PropTypes.number,
    };
  }

  render() {
    const classes = this.props.classes;
    const records = this.props.records || [];
    const columns = this.props.columns || [];
    const height = this.props.height || 500;

    return (
      <div>
        <div style={{marginLeft: 'auto', width: 80}}>
          {records.length}件
        </div>

        <div style={{height: height, overflow: 'scroll', marginBottom: '30px'}}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((c, cKey) => (
                  <TableCell key={cKey} className={classes.stickyHead} style={c.style} align="left">{c.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {records.map((r, rKey) => (
                <TableRow key={rKey}>
                  {columns.map((c, cKey) => (
                    <TableCell key={cKey} className={classes.simpleCell} style={c.style} align="left">{r[c.id]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

const forExport = withStyles(styles)(SimpleTable);
export {forExport as SimpleTable};
