import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {cowshedAPI} from 'api/cowshed.js';
import {DateUtil} from 'utils/date-util.js';
import {ViewMode} from 'models/view-mode.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ActionTable} from 'components/tables/ActionTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class CowshedIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'cowshedId', label: '牛舎ID'},
      {id: 'cowshedName', label: '牛舎名'},
      {id: 'drinkingSource', label: '飲水の取得元'},
      {id: 'feedLogicSwitchingDateDisplay', label: '採食判定切替日'},
      {id: 'heatIndexCalculationLogic', label: '発情指数算出'},
      {id: 'cowGroupCount', label: '牛群数'},
      {id: 'updatedAtDisaply', label: '最新更新日時'},
    ];

    this.state = {
      records: [],
      columns: columns,
      showProgress: false
    };
  }

  componentDidMount() {
    this.index();
  }

  createAction() {
    const params = {
      pathname: '/cowshed/create',
    };
    this.props.history.push(params);
  }

  updateAction(record) {
    const params = {
      pathname: `/cowshed/${record.cowshedId}/${ViewMode.UPDATE}`,
    };
    this.props.history.push(params);
  }

  deleteAction(record) {
    const params = {
      pathname: `/cowshed/${record.cowshedId}/${ViewMode.DELETE}`,
    };
    this.props.history.push(params);
  }

  index() {
    this.setState({showProgress: true});

    return cowshedAPI.index(sessionState.currentFarmId()).then((res) => {
      const records = res.data.map((r) => {
        r.feedLogicSwitchingDateDisplay = DateUtil.toYYYYMMDD(r.feedLogicSwitchingDate);
        r.updatedAtDisaply = DateUtil.toYYYYMMDDHHmm(r.updatedAt);
        r.canUpdate = true;
        r.canDelete = r.cowGroupCount === 0;
        return r;
      });

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>牛舎</h2>

        <ul className={classes.flexContainer}>
          <li>
            <FarmSelect changeAction={() => this.index()} />
          </li>

          <li style={{marginLeft: '100px'}}>
            <Button variant="contained" color="primary" onClick={() => this.createAction()}>
              登録
            </Button>
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <ActionTable
          records={this.state.records}
          columns={this.state.columns}
          updateAction={(record) => this.updateAction(record)}
          deleteAction={(record) => this.deleteAction(record)}
          linkColumnId="cowshedName"
        />
      </div>
    );
  }
}

CowshedIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(CowshedIndex));
export {forExport as CowshedIndex};
