import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {cowGroupAPI} from 'api/cow-group.js';
import {cowshedAPI} from 'api/cowshed.js';
import {CowGroup} from 'models/cow-group.js';
import {ErrorUtil} from 'utils/error-util.js';
import {ViewMode} from 'models/view-mode.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ReturnButton} from 'components/ReturnButton.js';
import {CowGroupInput} from 'components/forms/cow-group/CowGroupInput.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  readonlyText: GlobalCss.readonlyText,
};

class CowGroupEdit extends React.Component {
  constructor(props) {
    super();

    this.farm = sessionState.currentFarm();
    this.farmId = sessionState.currentFarmId();

    const params = props.match.params;
    this.cowGroupId = params.id ? parseInt(params.id, 10) : null;
    this.viewMode = new ViewMode(params.action);

    this.state = {
      record: {
        groupAttribute: this.farm.isMilk() ? 'milkingGroup' : 'fatteningGroup',
        pens: '',
        menuDisplayed: true,
        groupAttributeEditable: true,
      },
      loaded: false,
      showProgress: false,
      showDialog: false,
    };

    this.title = `牛群 - ${this.viewMode.label}`;
    this.notification = `${this.viewMode.label}が完了しました`;
    this.buttonColor = this.viewMode.isDelete() ? 'secondary' : 'primary';

    this.initialized = true;
  }

  componentDidMount() {
    cowshedAPI.index(this.farmId).then((res) => {
      this.cowsheds = res.data;

      if (this.viewMode.isCreate()) {
        const groupAttributes = CowGroup.groupAttributes(this.farm.isMilk());
        this.groupAttributes = groupAttributes.filter((a) => !a.default);

        const record = this.state.record;
        record.cowshedId = this.cowsheds[0].cowshedId;
        this.setState({
          record: record,
          loaded: true
        });
        return;
      }

      this.show();
    });
  }

  show() {
    this.setState({showProgress: true});

    return cowGroupAPI.show(this.farmId, this.cowGroupId).then((res) => {
      const record = Object.assign({}, res.data);

      this.groupAttributes = CowGroup.groupAttributes(this.farm.isMilk());

      const attribute = CowGroup.selectedGroupAttribute(record);
      record.groupAttribute = attribute.key || 'calfGroup';

      const defaultGroupAttribute = record.calfDefaultGroup || record.cullingDefaultGroup;
      record.groupAttributeEditable = !defaultGroupAttribute;

      const pens = record.pens || '';
      record.pens = pens.replace(/,/g, '\n');

      this.setState({
        record: record,
        loaded: true,
        showProgress: false
      });
    });
  }

  returnAction() {
    this.props.history.push('/cow_group');
  }

  syncState(key, value) {
    const input = this.state.input || {...this.state.record};
    input[key] = value;
    this.setState({input: input});
  }

  validate() {
    if (this.viewMode.isDelete()) {
      return {valid: true};
    }

    const input = this.state.input;
    const errors = [];

    if (!input) {
      errors.push('内容が変更されていません');
      return {valid: false, errors: errors};
    }

    if (!input.cowGroupName) {
      errors.push('牛群名が入力されていません');
    }

    return {valid: errors.length === 0, errors: errors};
  }

  save() {
    const result = this.validate();
    if (!result.valid) {
      this.setState({errorMessage: result.errors.join('\n')});
      return;
    } else {
      this.setState({errorMessage: ''});
    }

    let action;
    if (this.viewMode.isCreate()) {
      action = cowGroupAPI.create(this.farmId, this.createParams());
    } else if (this.viewMode.isUpdate()) {
      action = cowGroupAPI.update(this.cowGroupId, this.createParams());
    } else if (this.viewMode.isDelete()) {
      action = cowGroupAPI.delete(this.farmId, this.cowGroupId);
    }

    this.setState({showProgress: true});

    action.then((res) => {
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({showDialog: false});
        this.returnAction();
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({errorMessage: errorMessage});
      console.error(errorMessage);
    });
  }

  createParams() {
    const input = this.state.input;

    const params = {
      farmId: this.farmId,
      cowGroupName: input.cowGroupName,
      cowshedId: input.cowshedId,
      menuDisplayed: input.menuDisplayed,
    };

    CowGroup.allGroupAttributes.forEach((a) => {
      params[a.key] = false;
    });
    params[input.groupAttribute] = true;

    const inputPens = input.pens.split('\n');
    const pens = inputPens.map((pen) => pen.replace(/\s/g, '')).filter((pen) => pen);
    params.pens = pens.join(',');

    return params;
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>{this.title}</h2>

        <ul className={classes.flexContainer}>
          <li>
            <ReturnButton returnAction={() => this.returnAction()} />
          </li>

          <li style={{marginLeft: '500px'}}>
            <Button
              variant="contained"
              color={this.buttonColor}
              onClick={() => this.save()}
              disabled={this.state.showProgress}>
              {this.viewMode.label}
            </Button>
          </li>
        </ul>

        <div style={{marginLeft: '40px'}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <FarmSelect changeAction={() => {}} isDisabled={true} />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <DetailForm
          loaded={this.state.loaded}
          record={this.state.record}
          viewMode={this.viewMode}
          syncState={this.syncState.bind(this)}
          groupAttributes={this.groupAttributes}
          cowsheds={this.cowsheds}
          classes={classes}
        />
      </div>
    );
  }
}

CowGroupEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(CowGroupEdit));
export {forExport as CowGroupEdit};

const DetailForm = (props) => {
  if (props.loaded) {
    if (props.viewMode.isDelete()) {
      return (
        <DeleteForm classes={props.classes} record={props.record} />
      );
    } else {
      return (
        <CowGroupInput
          record={props.record}
          syncState={props.syncState}
          groupAttributes={props.groupAttributes}
          cowsheds={props.cowsheds}
        />
      );
    }
  } else {
    return null;
  }
};

DetailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};

const DeleteForm = (props) => {
  const classes = props.classes;
  const record = props.record;
  return (
    <div style={{marginLeft: '100px'}}>
      <TextField
        type="text"
        placeholder=""
        label="牛群名"
        value={record.cowGroupName}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
        margin="none"
        className={classes.readonlyText}
      />
    </div>
  );
};

DeleteForm.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};
