import {DateUtil} from 'utils/date-util.js';

export class CowEventColumn {
  static get dateColumns() {
    return [
      'targetBreedingDate',
      'occurredDiseaseDate',
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
    ];
  }

  static get datetimeColumns() {
    return ['plannedBredAt', 'reatmentDiseaseDate'];
  }

  static get eventTypeTreatment() {
    return [
      'infect',
      'injury',
      'lame',
      'mastitis',
      'otherDifficulty',
      'perinatalDifficulty',
      'breedingDifficulty'
    ];
  }

  static get treatmentCommonColumns() {
    return [
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
    ];
  }

  static get ignoreEmptyColumns() {
    return [
      'endDateOfMilkWashoutPeriod',
      'endDateOfBeefWashoutPeriod',
    ];
  }

  static get eventTypeFieldMapping() {
    return {
      abort: ['afterCowState'],
      breedingDifficulty: [
        'workerName',
        'diagnosisResultDetail',
        'bodyTemperature',
        'masterMedicineIds',
        'medicineMethods',
        'medicineCapacities',
        'difficultiesOvaryStill',
        'difficultiesOvaryCystic',
        'difficultiesRemnant',
        'difficultiesMetritis',
        'difficultiesPyometra',
        'diagnosisResultOfOvaryR',
        'diagnosisResultOfOvaryL',
        'uterusDiagnosisResult'
      ],
      bunben: [
        'childNumber',
        'calvingDifficulty',
        'birthResult1',
        'birthResult2',
        'birthResult3',
        'birthResult4',
        'birthSex1',
        'birthSex2',
        'birthSex3',
        'birthSex4',
        'calfNo1',
        'calfNo2',
        'calfNo3',
        'calfNo4',
        'calfBreed1',
        'calfBreed2',
        'calfBreed3',
        'calfBreed4',
        'birthState1',
        'birthState2',
        'birthState3',
        'birthState4',
        'weightOnBirth1',
        'weightOnBirth2',
        'weightOnBirth3',
        'weightOnBirth4',
      ],
      carcass: [
        'grade',
        'yieldGrade',
        'meetGrade',
        'beforeSlaughterWeight',
        'dressedCarcassWeightOfL',
        'dressedCarcassWeightOfR',
        'loinArea',
        'ribsThickness',
        'subcutaneousFat',
        'yieldBaseValue',
        'bmsNo',
        'marblingGrade',
        'bcsNo',
        'gloss',
        'bcsAndGlossGrade',
        'tight',
        'texture',
        'tightAndTextureGrade',
        'bfsNo',
        'fatLuster',
        'bfsAndFatLusterGrade',
        'carcassDefects',
        'otherFaultCorrection',
        'dressedCarcassUnitPrice',
        'dressedCarcassSalesPrice',
        'visceralDestruction',
        'slaughterNo',
        'totalDressedCarcassWeight',
      ],
      castration: ['castratMethod'],
      dehorn: ['dehornMethod'],
      fattening: [],
      freshCheck: [
        'bcs',
        'otherReproductionWork',
        'freshCheckResult',
        'uterusDiagnosisResult',
        'diagnosisResultOfOvaryR',
        'diagnosisResultOfOvaryL',
      ],
      gyugunidou: [
        'moveToCowGroupId',
        'beforeCowGroupId',
        'moveToPen',
        'beforePen',
      ],
      hatsujo: ['plannedBredAt'],
      heishi: [
        'deathReason',
        'expelledReason',
      ],
      hoofTrim: [],
      hormoneProgram: [
        'hormoneProgramName',
        'hormoneName',
      ],
      infect: [
        'occurredDiseaseDate',
        'workerName',
        'diagnosisResultDetail',
        'bodyTemperature',
        'masterMedicineIds',
        'medicineMethods',
        'medicineCapacities',
        'difficultiesPneumonia',
        'difficultiesDiarrhea',
        'difficultiesSkinDisease',
        'difficultiesOther',
      ],
      injury: [
        'occurredDiseaseDate',
        'workerName',
        'diagnosisResultDetail',
        'bodyTemperature',
        'masterMedicineIds',
        'medicineMethods',
        'medicineCapacities',
        'difficultiesHipDislocation',
        'difficultiesTeatInjury',
        'difficultiesOther',
      ],
      kannyu: [
        'selectedDryPeriod',
        'bcs',
      ],
      lame: [
        'occurredDiseaseDate',
        'workerName',
        'diagnosisResultDetail',
        'bodyTemperature',
        'masterMedicineIds',
        'medicineMethods',
        'medicineCapacities',
        'locomotionScore',
        'lameAffectedLimb1',
        'lameAffectedLimb2',
        'lameAffectedLimb3',
        'lameAffectedLimb4',
        'lameAffectedPart1',
        'lameAffectedPart2',
        'lameAffectedPart3',
        'lameAffectedPart4',
        'lameClawZones1',
        'lameClawZones2',
        'lameClawZones3',
        'lameClawZones4',
        'lameCondition1',
        'lameCondition2',
        'lameCondition3',
        'lameCondition4',
        'clawDiseaseName1',
        'clawDiseaseName2',
        'clawDiseaseName3',
        'clawDiseaseName4',
      ],
      mastitis: [
        'occurredDiseaseDate',
        'workerName',
        'diagnosisResultDetail',
        'bodyTemperature',
        'masterMedicineIds',
        'medicineMethods',
        'medicineCapacities',
        'mastitisBacteriaOfBreastFl',
        'mastitisBacteriaOfBreastFr',
        'mastitisBacteriaOfBreastBl',
        'mastitisBacteriaOfBreastBr',
        'mastitisDiagnosisResultOfBreastFl',
        'mastitisDiagnosisResultOfBreastFr',
        'mastitisDiagnosisResultOfBreastBl',
        'mastitisDiagnosisResultOfBreastBr',
        'stopMilkingOfBreastBr',
        'stopMilkingOfBreastFr',
        'stopMilkingOfBreastBl',
        'stopMilkingOfBreastFl',
        'mastitisCowGroupId',
      ],
      measurement: [
        'weight',
        'dg',
        'breedingDays',
      ],
      ninshinkantei: [
        'ninshinkanteiResult',
        'judgePregnantTiming',
        'targetBreedingDate',
        'otherReproductionWork',
      ],
      observation: [
        'earCondition',
        'hipCondition',
        'bodyCondition',
        'urineCondition',
        'muzzleCondition',
        'fecesCondition',
        'hairCondition',
        'eyeCondition',
        'bodyTemperature',
      ],
      otherDifficulty: [
        'occurredDiseaseDate',
        'workerName',
        'diagnosisResultDetail',
        'bodyTemperature',
        'masterMedicineIds',
        'medicineMethods',
        'medicineCapacities',
        'difficultiesOther',
      ],
      perinatalDifficulty: [
        'occurredDiseaseDate',
        'workerName',
        'examinationTreatmentKind',
        'bodyTemperature',
        'masterMedicineIds',
        'medicineMethods',
        'medicineCapacities',
        'difficultiesKetosis',
        'difficultiesFattyLiver',
        'difficultiesAbomasumDisplacement',
        'difficultiesHypocalcium',
        'difficultiesAcidosis',
        'difficultiesMetritis',
        'difficultiesDowner',
        'difficultiesStagnantFood',
        'difficultiesPlacenta',
        'difficultiesUrolithiasis',
        'difficultiesVitaminA',
        'difficultiesTympanites',
      ],
      sagyomemo: ['taioTitle'],
      shukkateishikaijo: [],
      tanetsuke: [
        'tanetsukeMethod',
        'workerName',
        'inseminationCode',
        'bullCowId',
        'masterSpermId',
        'otherReproductionWork',
        'donorRegistrationNo',
        'donorBreed',
        'donorName',
        'donorSpermNo',
        'motherRegistrationNoOfDonor',
        'motherNameOfDonor',
        'donorCowUid',
        'donorBreedingValue',
        'fatherRegistrationNoOfDonor',
        'fatherNameOfDonor',
        'motherBreedOfDonor',
        'maternalGrandfatherRegistrationNoOfDonor',
        'maternalGrandfatherSpermNoOfDonor',
        'maternalGrandfatherNameOfDonor',
        'bredForEmbryoRecovery',
      ],
      touta: [
        'buyer',
        'salesPrice',
        'shipmentNo',
        'producingArea',
        'producingFarmName',
        'expelledReason',
      ],
      vaccine: [
        'masterMedicineIds',
        'vaccineTiming',
      ],
      wean: [],
      embryo_recovery: [
        'embryoRecoveryRankA',
        'embryoRecoveryRankB',
        'embryoRecoveryRankC',
        'embryoRecoveryRankD',
        'embryoRecoveryUnfertilized',
        'masterSpermId',
        'targetBreedingDate',
        'hormoneProgramName',
      ],
    };
  }

  static get columnLables() {
    return {
      afterCowState: '牛個体の状態(戻り先)',
      bcs: 'bcs',
      bcsAndGlossGrade: '肉色及び光沢の等級',
      bcsNo: 'bcsno',
      beforeSlaughterWeight: 'と殺前重量',
      bfsAndFatLusterGrade: '色沢と質の等級',
      bfsNo: 'bfsno',
      birthResult1: '子牛出産結果(1頭目)',
      birthResult2: '子牛出産結果(2頭目)',
      birthResult3: '子牛出産結果(3頭目)',
      birthResult4: '子牛出産結果(4頭目)',
      birthSex1: '出産子牛性別(1頭目)',
      birthSex2: '出産子牛性別(2頭目)',
      birthSex3: '出産子牛性別(3頭目)',
      birthSex4: '出産子牛性別(4頭目)',
      birthState1: '出生時状態1st',
      birthState2: '出生時状態2nd',
      birthState3: '出生時状態3rd',
      birthState4: '出生時状態4th',
      bmsNo: 'bmsno',
      bodyCondition: '体の状態',
      bodyTemperature: '体温',
      bredForEmbryoRecovery: '採卵用の授精',
      breedingDays: '飼育日数',
      bullCowId: '種牛情報',
      buyer: '販売先',
      calfBreed1: '子牛品種(1頭目)',
      calfBreed2: '子牛品種(2頭目)',
      calfBreed3: '子牛品種(3頭目)',
      calfBreed4: '子牛品種(4頭目)',
      calfCowId1: '子牛情報(1頭目)',
      calfCowId2: '子牛情報(2頭目)',
      calfCowId3: '子牛情報(3頭目)',
      calfCowId4: '子牛情報(4頭目)',
      calfNo1: '子牛番号(1頭目)',
      calfNo2: '子牛番号(2頭目)',
      calfNo3: '子牛番号(3頭目)',
      calfNo4: '子牛番号(4頭目)',
      calvingDifficulty: '分娩難易度',
      castratMethod: '去勢方法',
      childNumber: '出生頭数',
      clawDiseaseName1: '蹄病名1',
      clawDiseaseName2: '蹄病名2',
      clawDiseaseName3: '蹄病名3',
      clawDiseaseName4: '蹄病名4',
      comment: 'コメント',
      deadProcessCost: '死亡処理手数料',
      deathReason: 'へい死理由',
      dehornMethod: '除角方法',
      dg: 'dg',
      diagnosisResultDetail: '診断結果詳細',
      diagnosisResultOfOvaryL: '卵巣診断結果(左)',
      diagnosisResultOfOvaryR: '卵巣診断結果(右)',
      difficultiesAbomasumDisplacement: '第四胃変位軽重度',
      difficultiesAcidosis: 'ルーメンアシドーシス軽重度',
      difficultiesDiarrhea: '下痢軽重度',
      difficultiesDowner: 'ダウナー症候群軽重度',
      difficultiesFattyLiver: '脂肪肝軽重度',
      difficultiesHipDislocation: '股関節脱臼軽重度',
      difficultiesHypocalcium: '低カルシウム血症(乳熱)軽重度',
      difficultiesKetosis: 'ケトーシス軽重度',
      difficultiesMetritis: '子宮炎軽重度',
      difficultiesOther: 'その他軽重度',
      difficultiesOvaryCystic: '卵胞嚢腫軽重度',
      difficultiesOvaryStill: '卵巣静止・萎縮軽重度',
      difficultiesPlacenta: '胎盤停滞軽重度',
      difficultiesPneumonia: '肺炎軽重度',
      difficultiesPyometra: '子宮蓄膿症軽重度',
      difficultiesRemnant: '黄体遺残軽重度',
      difficultiesSkinDisease: '皮膚病軽重度',
      difficultiesStagnantFood: '食滞軽重度',
      difficultiesTeatInjury: '乳頭損傷軽重度',
      difficultiesTympanites: '鼓張症軽重度',
      difficultiesUrolithiasis: '尿石軽重度',
      difficultiesVitaminA: 'ビタミンa欠乏症軽重度',
      donorBreed: '供卵牛の品種',
      donorBreedingValue: '供卵牛の育種価',
      donorCowUid: '供卵牛の個体識別番号',
      donorName: '供卵牛の名前',
      donorRegistrationNo: '供卵牛の登録番号',
      donorSpermNo: '供卵父牛の精液ラベル番号',
      dressedCarcassSalesPrice: '枝肉販売金額(万)',
      dressedCarcassUnitPrice: '枝肉単価(kg)',
      dressedCarcassWeightOfL: '枝肉重量(左)',
      dressedCarcassWeightOfR: '枝肉重量(右)',
      earCondition: '耳の状態',
      embryoRecoveryRankA: 'ランクaの個数',
      embryoRecoveryRankB: 'ランクbの個数',
      embryoRecoveryRankC: 'ランクcの個数',
      embryoRecoveryRankD: 'ランクdの個数',
      embryoRecoveryUnfertilized: '未授精の個数',
      endDateOfMilkWashoutPeriod: '搾乳休薬終了日',
      endDateOfBeefWashoutPeriod: '食肉休薬終了日',
      eventName: 'イベント名称',
      examinationTreatmentKind: '診察治療種別',
      expelledReason: '除籍理由',
      eyeCondition: '目の状態',
      fatLuster: '脂肪の色沢と質',
      fatherNameOfDonor: '供卵父牛の名前',
      fatherRegistrationNoOfDonor: '供卵父牛の登記番号',
      fecesCondition: '糞の状態',
      freshCheckResult: 'フレッシュチェック結果',
      gloss: '光沢',
      grade: '歩留肉質等級',
      hairCondition: '毛の状態',
      hipCondition: '尻の状態',
      hormoneName: '処置内容',
      hormoneProgramName: 'ホルモンプログラム名',
      inseminationCode: '授精コード',
      judgePregnantTiming: '妊娠鑑定タイミング',
      lameAffectedLimb1: '蹄病-肢1',
      lameAffectedLimb2: '蹄病-肢2',
      lameAffectedLimb3: '蹄病-肢3',
      lameAffectedLimb4: '蹄病-肢4',
      lameAffectedPart1: '蹄病-部位1',
      lameAffectedPart2: '蹄病-部位2',
      lameAffectedPart3: '蹄病-部位3',
      lameAffectedPart4: '蹄病-部位4',
      lameClawZones1: '蹄病-蹄箇所1',
      lameClawZones2: '蹄病-蹄箇所2',
      lameClawZones3: '蹄病-蹄箇所3',
      lameClawZones4: '蹄病-蹄箇所4',
      lameCondition1: '蹄病-状態1',
      lameCondition2: '蹄病-状態2',
      lameCondition3: '蹄病-状態3',
      lameCondition4: '蹄病-状態4',
      locomotionScore: '跛行スコア',
      loinArea: '胸最長筋面積',
      marblingGrade: '脂肪交雑等級',
      masterMedicineIds: '投薬品id',
      masterSpermId: '精液id',
      mastitisBacteriaOfBreastBl: '乳房炎菌(左後)',
      mastitisBacteriaOfBreastBr: '乳房炎菌(右後)',
      mastitisBacteriaOfBreastFl: '乳房炎菌(左前)',
      mastitisBacteriaOfBreastFr: '乳房炎菌(右前)',
      mastitisCowGroupId: '乳房炎発生時の牛群id',
      mastitisDiagnosisResultOfBreastBl: '乳房炎診断結果(左後)',
      mastitisDiagnosisResultOfBreastBr: '乳房炎診断結果(右後)',
      mastitisDiagnosisResultOfBreastFl: '乳房炎診断結果(左前)',
      mastitisDiagnosisResultOfBreastFr: '乳房炎診断結果(右前)',
      maternalGrandfatherNameOfDonor: '供卵母父牛の名前',
      maternalGrandfatherRegistrationNoOfDonor: '供卵母父牛の登記番号',
      maternalGrandfatherSpermNoOfDonor: '供卵母父牛の精液番号',
      meetGrade: '肉質等級',
      motherBreedOfDonor: '供卵母牛の品種',
      motherNameOfDonor: '供卵母牛の名前',
      motherRegistrationNoOfDonor: '供卵母牛の登録番号',
      moveToCowGroupId: '移動先牛群id',
      moveToPen: '移動先牛房',
      muzzleCondition: '鼻の状態',
      ninshinkanteiResult: '妊娠鑑定結果',
      occurredAt: '発生日時',
      occurredDiseaseDate: '疾病発生日時',
      otherFaultCorrection: 'その他瑕疵歩留補正',
      otherReproductionWork: 'その他処置内容',
      producingArea: '生産地',
      producingFarmName: '生産農場名',
      ribsThickness: 'ばらの厚さ',
      salesPrice: '販売価格',
      selectedDryPeriod: '乾乳前後期',
      shipmentNo: '出荷番号',
      slaughterNo: 'と畜番号',
      stopMilkingOfBreastBl: '盲乳処置(左後)',
      stopMilkingOfBreastBr: '盲乳処置(右後)',
      stopMilkingOfBreastFl: '盲乳処置(左前)',
      stopMilkingOfBreastFr: '盲乳処置(右前)',
      subcutaneousFat: '皮下脂肪の厚さ',
      taioTitle: 'タイトル',
      tanetsukeMethod: '種付方法',
      targetBreedingDate: '対象種付日',
      targetShipmentKind: '対象出荷種類',
      texture: 'きめ',
      tight: '締まり',
      tightAndTextureGrade: '締まり及びきめの等級',
      totalDressedCarcassWeight: '枝肉総重量',
      urineCondition: '尿の状態',
      uterusDiagnosisResult: '子宮診断結果',
      vaccineTiming: 'ワクチンタイミング',
      visceralDestruction: '内臓破棄',
      weight: '体重',
      weightOnBirth1: '出生時体重(1頭目)',
      weightOnBirth2: '出生時体重(2頭目)',
      weightOnBirth3: '出生時体重(3頭目)',
      weightOnBirth4: '出生時体重(4頭目)',
      workerName: '作業者名',
      yieldBaseValue: '歩留基準値',
      yieldGrade: '歩留等級',
      beforeCowGroupId: '移動元牛群id',
      beforePen: '移動元牛房',
      medicineMethods: '投与方法',
      medicineCapacities: '投与量',
      plannedBredAt: '種付予定日',
      carcassDefects: '瑕疵'
    };
  }

  static generateDetail(cowEvent) {
    let columns = CowEventColumn.eventTypeFieldMapping[cowEvent.eventType];
    if (!columns || columns.length === 0) {
      return '';
    }
    if (CowEventColumn.eventTypeTreatment.includes(cowEvent.eventType)) {
      columns = columns.concat(CowEventColumn.treatmentCommonColumns);
    }

    const values = columns.map((column) => {
      let value = cowEvent[column];
      if (value) {
        if (CowEventColumn.dateColumns.includes(column)) {
          value = DateUtil.toYYYYMMDD(value);
        } else if (CowEventColumn.datetimeColumns.includes(column)) {
          value = DateUtil.toYYYYMMDDHHmm(value);
        }
      } else {
        if (CowEventColumn.ignoreEmptyColumns.includes(column)) {
          return null;
        }
        value = '';
      }

      const label = CowEventColumn.columnLables[column] || column;

      return `${label}: ${value}`;
    });

    if (cowEvent.comment) {
      values.push(`コメント: ${cowEvent.comment}`);
    }

    return values.filter((v) => v).join(', ');
  }
}
