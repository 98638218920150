import {apiBase} from 'api/base.js';

class PushLogAPI {
  index(farmId) {
    const query = farmId ? `?farm_id=${farmId}` : '';
    return apiBase.get(`/admin/push_log${query}`);
  }
}

export const pushLogAPI = new PushLogAPI();
