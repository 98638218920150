import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {withRouter} from 'react-router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit.js';
import DeleteIcon from '@material-ui/icons/Delete.js';
import CheckCircle from '@material-ui/icons/CheckCircle.js';
import Link from '@material-ui/core/Link';

const styles = {
  stickyHead: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    fontSize: '16px',
    whiteSpace: 'nowrap'
  },
  simpleCell: {
    fontSize: '14px',
    padding: '5px 0 5px 16px',
    maxWidth: 300,
    wordBreak: 'break-all'
  },
  booleanCell: {
    fontSize: '14px',
    padding: '10px 0 5px 16px',
    minWidth: 60,
  },
  actionCell: {
    fontSize: '14px',
    padding: '5px 0 5px 16px',
    width: 60,
  },
  cellButton: {
    borderStyle: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer'
  },
  linkCell: {
    fontSize: '14px',
    padding: '5px 0 5px 16px',
    minWidth: 200,
    wordBreak: 'break-all'
  },
};

class ActionTable extends React.Component {
  constructor(props) {
    super();
  }

  static get propTypes() {
    return {
      classes: PropTypes.object.isRequired,
      records: PropTypes.array,
      columns: PropTypes.array.isRequired,
      updateAction: PropTypes.func.isRequired,
      deleteAction: PropTypes.func,
      linkColumnId: PropTypes.string,
    };
  }

  render() {
    const classes = this.props.classes;
    const records = this.props.records || [];
    const columns = this.props.columns || [];
    const updateAction = this.props.updateAction;
    const deleteAction = this.props.deleteAction;
    const linkColumnId = this.props.linkColumnId || '';

    return (
      <div>
        <div style={{marginLeft: 'auto', width: 80}}>
          {records.length}件
        </div>

        <div style={{height: '500px', overflow: 'scroll', marginBottom: '30px'}}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((c, cKey) => (
                  <TableCell key={cKey} className={classes.stickyHead}>{c.label}</TableCell>
                ))}

                <TableCell key={'action'} className={classes.stickyHead}>編集</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {records.map((r, rKey) => (
                <TableRow key={rKey}>
                  {columns.map((c, index) => (
                    c.id === linkColumnId ?
                      <LinkCell
                        linkAction={() => updateAction(r)}
                        key={index}
                        cKey={index}
                        label={r[c.id]}
                        classes={classes} />
                      : <ValueCell key={index} cKey={index} classes={classes} type={c.type} value={r[c.id]} />
                  ))}

                  <TableCell className={classes.actionCell}>
                    <UpdateActionCell canUpdate={r.canUpdate} updateAction={() => updateAction(r)} classes={classes} />
                    <DeleteActionCell canDelete={r.canDelete} deleteAction={() => deleteAction(r)} classes={classes} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

const ValueCell = (props) => {
  if (props.type === 'boolean') {
    if (props.value) {
      return (
        <TableCell key={props.cKey} className={props.classes.booleanCell}>
          <CheckCircle color="action" fontSize="small" />
        </TableCell>
      );
    } else {
      return (
        <TableCell key={props.cKey} className={props.classes.simpleCell}></TableCell>
      );
    }
  } else {
    return (
      <TableCell key={props.cKey} className={props.classes.simpleCell}>{props.value}</TableCell>
    );
  }
};

ValueCell.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  cKey: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

const UpdateActionCell = (props) => {
  if (props.canUpdate) {
    return (
      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
        <button
          onClick={props.updateAction}
          className={props.classes.cellButton}
          style={{marginRight: '10px'}}
        >
          <EditIcon color="primary" fontSize="small" />
        </button>
      </div>
    );
  } else {
    return null;
  }
};

const DeleteActionCell = (props) => {
  if (props.canDelete) {
    return (
      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
        <button
          onClick={props.deleteAction}
          className={props.classes.cellButton}
          style={{marginRight: '10px'}}
        >
          <DeleteIcon color="secondary" fontSize="small" />
        </button>
      </div>
    );
  } else {
    return null;
  }
};

const LinkCell = (props) => {
  return (
    <TableCell key={props.cKey} className={props.classes.linkCell}>
      <Link href="#" onClick={props.linkAction}>
        {props.label}
      </Link>
    </TableCell>
  );
};

LinkCell.propTypes = {
  cKey: PropTypes.number.isRequired,
  linkAction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(ActionTable));
export {forExport as ActionTable};
