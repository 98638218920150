import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

const styles = {};

class DatePickerWrapper extends React.Component {
  constructor(props) {
    super();

    this.label = props.label;
    this.syncState = props.syncState;

    this.state = {
      date: props.date
    };
  }

  onChangeDate(value) {
    this.setState(() => ({
      date: value
    }));
    this.syncState(value);
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="YYYY/MM/DD"
          margin="none"
          id="date-picker-wrapper"
          label={this.label}
          value={this.state.date}
          onChange={(value) => this.onChangeDate(value)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          fullWidth
        />
      </MuiPickersUtilsProvider>
    );
  }
}

DatePickerWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  date: PropTypes.object,
  syncState: PropTypes.func.isRequired,
};

const forExport = withStyles(styles)(DatePickerWrapper);
export {forExport as DatePickerWrapper};
