import {apiBase} from 'api/base.js';

class ActionLogAPI {
  index(farmId, accountId = null) {
    let params = `farm_id=${farmId}`;
    if (accountId) {
      params += `&account_id=${accountId}`;
    }
    return apiBase.get(`/admin/action_log?${params}`);
  }

  show(farmId, id) {
    return apiBase.get(`/admin/action_log/${id}?farm_id=${farmId}`);
  }

  rollback(farmId, id) {
    const params = {farmId: farmId};
    return apiBase.put(`/admin/action_log/${id}`, params);
  }
}

export const actionLogAPI = new ActionLogAPI();
