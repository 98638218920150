import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  // Dialog のY方向の表示位置を画面上部に固定する
  scrollPaper: {
    alignItems: 'baseline'
  },
};

class SelectDialog extends React.Component {
  constructor(props) {
    super();

    this.onClose = props.onClose;
    this.filterCondition = '';

    this.state = {
      rawItems: props.items || [],
      items: [],
    };
  }

  componentDidMount() {
    this.filter();
  }

  ok() {
    this.setState({showDialog: false});

    const result = this.props.items
      .filter((item) => item.checked)
      .map((item) => {
        return {
          key: item.key,
          label: item.label,
          notes: item.notes,
        };
      });
    this.onClose(result);
  }

  cancel() {
    this.setState({showDialog: false});
    this.onClose();
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
  }

  onChangeCheckbox(item, e) {
    item.checked = e.target.checked;
  }

  onChangeFilterCondition(e) {
    this.filterCondition = e.target.value;
    this.filter();
  }

  filter() {
    let items = [].concat(this.state.rawItems);
    if (this.filterCondition) {
      items = items.filter((r) => {
        return r.label.includes(this.filterCondition);
      });
    }

    this.setState(() => ({
      items: items
    }));
  }

  render() {
    const classes = this.props.classes;
    const title = this.props.title || '選択ダイアログ';
    const description = this.props.description || '対象を選択してください。';
    const minWidth = this.props.minWidth || 500;

    return (
      <div>
        <Dialog
          open={this.props.showDialog}
          aria-labelledby="form-dialog-title"
          classes={{scrollPaper: classes.scrollPaper}}
        >

          <DialogTitle id="form-dialog-title">
            {title}
          </DialogTitle>

          <div style={{marginLeft: 30}}>
            <DialogContentText>
              {description}
            </DialogContentText>

            <TextField
              type="text"
              label="キーワードで絞込"
              value={this.filterCondition}
              onChange={(e) => this.onChangeFilterCondition(e)}
              variant="outlined"
              margin="dense"
              style={{width: 300, marginBottom: 20}}
            />
          </div>

          <DialogContent style={{minWidth: minWidth}}>
            {this.state.items.map((item, index) => {
              const displayLabel = item.notes ? `${item.label}  (${item.notes})` : item.label;

              return (
                <div key={index} style={{marginBottom: 10}}>
                  <div className={classes.flexContainer}>
                    <Checkbox
                      onChange={(e) => this.onChangeCheckbox(item, e)}
                      color="primary"
                    />

                    <TextField
                      type="text"
                      value={displayLabel}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="none"
                      style={{width: 400}}
                    />
                  </div>
                </div>
              );
            })}
          </DialogContent>

          <DialogActions>
            <Button onClick={() => this.cancel()} color="secondary">
              キャンセル
            </Button>

            <Button onClick={() => this.ok()} color="primary">
              決定
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

SelectDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  minWidth: PropTypes.number,
};

const forExport = withRouter(withStyles(styles)(SelectDialog));
export {forExport as SelectDialog};
