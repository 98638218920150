import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import {DatePickerWrapper} from 'components/DatePickerWrapper.js';

const styles = {
  inputArea: {
    marginBottom: '30px',
  },
  textField: {
    width: 300,
    marginBottom: '20px',
  },
};

class CowshedInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.record);
    this.syncState = props.syncState;

    this.bleIdItems = [
      {columnId: 'bleId1', label: 'センサー番号1'},
      {columnId: 'bleId2', label: 'センサー番号2'},
      {columnId: 'bleId3', label: 'センサー番号3'},
      {columnId: 'bleId4', label: 'センサー番号4'},
      {columnId: 'bleId5', label: 'センサー番号5'}
    ];
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
    this.syncState(key, e.target.value);
  }

  onChangeDate(key, value) {
    this.setState(() => ({[key]: value}));
    this.syncState(key, value);
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '100px'}}>
        <form className={classes.inputArea}>
          <div>
            <TextField
              type="text"
              required={true}
              placeholder=""
              label="牛舎名"
              value={this.state.cowshedName}
              onChange={(e) => this.onChangeInput(e, 'cowshedName')}
              margin="none"
              className={classes.textField}
            />
          </div>

          {this.props.disableFeedLogicSwitchingDate ? null :
            <div className={classes.textField}>
              <DatePickerWrapper
                label="採食判定切替日"
                date={this.state.feedLogicSwitchingDate}
                syncState={(value) => this.onChangeDate('feedLogicSwitchingDate', value)}
              />
            </div>
          }

          <div className={classes.textField}>
            <InputLabel htmlFor="drinking-source" style={{fontSize: '12px'}}>飲水の取得元</InputLabel>
            <Select
              value={this.state.drinkingSource}
              onChange={(e) => this.onChangeInput(e, 'drinkingSource')}
              input={<Input id="drinking-source" />}
              style={{minWidth: '150px'}}
            >
              <MenuItem value={'mimosa'}>mimosa</MenuItem>
              <MenuItem value={'coax'}>coax</MenuItem>
              <MenuItem value={'none'}>none</MenuItem>
            </Select>
          </div>

          <div className={classes.textField}>
            <InputLabel htmlFor="heat-index-calculation-logic" style={{fontSize: '12px'}}>発情指数算出ロジック</InputLabel>
            <Select
              value={this.state.heatIndexCalculationLogic}
              onChange={(e) => this.onChangeInput(e, 'heatIndexCalculationLogic')}
              input={<Input id="heat-index-calculation-logic" />}
              style={{minWidth: '150px'}}
            >
              <MenuItem value={'SLICE3H'}>SLICE3H</MenuItem>
              <MenuItem value={'TS1908'}>TS1908</MenuItem>
            </Select>
          </div>

          {this.bleIdItems.map((item, index) => (
            <div key={index}>
              <TextField
                type="text"
                label={item.label}
                value={this.state[item.columnId]}
                onChange={(e) => this.onChangeInput(e, item.columnId)}
                margin="none"
                className={classes.textField}
              />
            </div>
          ))}
        </form>
      </div>
    );
  }
}

CowshedInput.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
  disableFeedLogicSwitchingDate: PropTypes.bool
};

const forExport = withStyles(styles)(CowshedInput);
export {forExport as CowshedInput};
