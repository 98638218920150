import {apiBase} from 'api/base.js';

class FarmAPI {
  index(params) {
    return apiBase.get('/admin/farm');
  }

  digest() {
    return apiBase.get('/admin/farm/digest');
  }

  show(farmId) {
    return apiBase.get(`/admin/farm/${farmId}`);
  }

  create(params) {
    return apiBase.post('/admin/farm', params);
  }

  update(farmId, params) {
    return apiBase.put(`/admin/farm/${farmId}`, params);
  }

  delete(farmId, farmName) {
    const params = {farmName: farmName};
    return apiBase.delete(`/admin/farm/${farmId}`, params);
  }
}

export const farmAPI = new FarmAPI();
