import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';

const styles = {
  inputArea: {
    marginBottom: '30px',
  },
  textField: {
    width: 300,
    marginBottom: '20px',
  },
};

class AlertMailInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.record);
    this.syncState = props.syncState;
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
    this.syncState(key, e.target.value);
  }

  onChangeCheckbox(e, key) {
    this.setState({[key]: e.target.checked});
    this.syncState(key, e.target.checked);
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '100px'}}>
        <form className={classes.inputArea}>
          <div>
            <TextField
              type="text"
              required={true}
              placeholder=""
              label="ユーザー名"
              value={this.state.userName}
              onChange={(e) => this.onChangeInput(e, 'userName')}
              margin="none"
              className={classes.textField}
            />
          </div>

          <div>
            <TextField
              type="email"
              required={true}
              placeholder=""
              label="メールアドレス"
              value={this.state.mailAddress}
              onChange={(e) => this.onChangeInput(e, 'mailAddress')}
              margin="none"
              className={classes.textField}
            />
          </div>

          <div>
            <InputLabel style={{fontSize: '12px'}}>通知するアラート</InputLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.dysstasia}
                  onChange={(e) => this.onChangeCheckbox(e, 'dysstasia')}
                  color="primary"
                />
              }
              label="起立困難"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.calving}
                  onChange={(e) => this.onChangeCheckbox(e, 'calving')}
                  color="primary"
                />
              }
              label="分娩"
            />
          </div>
        </form>
      </div>
    );
  }
}

AlertMailInput.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
};

const forExport = withStyles(styles)(AlertMailInput);
export {forExport as AlertMailInput};
