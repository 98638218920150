import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {sessionState} from 'session/session-state.js';
import {cowDeleteAPI} from 'api/cow-delete.js';
import {StringUtil} from 'utils/string-util.js';
import {ErrorUtil} from 'utils/error-util.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {SimpleTable} from 'components/tables/SimpleTable.js';
import {GlobalCss} from 'components/global-css.js';
import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  selectField: {
    minWidth: 100,
  },
  longTextField: {
    width: 500,
  },
  enclosedArea: GlobalCss.enclosedArea,
};

class CowDeleteIndex extends React.Component {
  constructor() {
    super();

    this.state = {
      records: [],
      columns: [],
      notes: '',
      showInput: false,
      canExecute: false,
      showProgress: false,
      showDialog: false
    };
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
  }

  pasteAction() {
    navigator.clipboard.readText().then((text) => {
      const result = StringUtil.parseExcelMatrix(text);
      if (result.valid) {
        this.rows = result.rows;
        this.drawInputList(result.headers, result.values);
      } else {
        this.setState({
          showInput: false,
          canExecute: false
        });
      }
    });
  }

  copyInputSample() {
    const headers = ['個体識別番号', '牛番号', '子牛番号', 'cow_id', '説明'].join('\t');
    const samples = [
      ['1234567890', '101', '', '', '「個体識別番号」、「子牛番号」、「cow_id」列が取込対象です'],
      ['', '102', '501', '', '「説明」列と「牛番号」列は取込対象外です'],
      ['', '', '', '123456', 'cow_id、個体識別番号、子牛番号の優先順序で処理されます'],
    ];
    const values = samples.map((s) => s.join('\t')).join('\n');

    const dummy = document.createElement('textarea');
    dummy.value = `${headers}\n${values}`;
    document.body.appendChild(dummy);
    dummy.select();
    document.execCommand('copy');
    dummy.parentElement.removeChild(dummy);
  }

  drawInputList(headers, values) {
    headers.unshift('Excelの行番号');
    const columns = headers.map((header, index) => {
      return {
        id: String(index),
        label: header,
        style: {width: 100, minWidth: 100}
      };
    });

    const records = values.map((value, index) => {
      const lineNo = index + 2;
      const result = {'0': lineNo};
      value.forEach((v, colIndex) => {
        result[String(colIndex + 1)] = v;
      });
      return result;
    });

    this.setState({
      records: records,
      columns: columns,
      showInput: true,
      canExecute: true,
    });
  }

  save() {
    const params = {
      accountId: sessionState.accountId(),
      farmId: sessionState.currentFarmId(),
      dataType: 'cow_delete',
      rows: this.rows,
      notes: this.state.notes
    };
    this.setState({showProgress: true});

    cowDeleteAPI.create(params).then((res) => {
      this.notification = '削除が完了しました';
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({
          showDialog: false,
          canExecute: false,
          errorMessage: '',
        });
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({
        errorMessage: errorMessage,
        canExecute: false,
      });
      console.error(errorMessage);
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>牛個体一括削除</h2>

        <ul className={classes.flexContainer}>
          <li style={{marginRight: 50}}>
            <FarmSelect />
          </li>

          <li style={{marginRight: 30}}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.pasteAction()}
            >
              貼付
            </Button>
          </li>

          <li>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.save()}
              disabled={!this.state.canExecute}
            >
              実行
            </Button>
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <ul className={classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
          <li style={{width: 50, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>備考</InputLabel>
          </li>

          <li style={{marginRight: 30}}>
            <TextField
              type="text" margin="dense" style={{width: 620}}
              value={this.state.notes}
              variant="outlined" multiline={true} rows={2}
              onChange={(e) => this.onChangeInput(e, 'notes')}
            />
          </li>

          <li style={{width: 140}}>
            <div className={classes.enclosedArea} style={{marginTop: 10}}>
              <div style={{marginBottom: 10, fontStyle: 'italic', color: '#696969'}}>
                入力サンプル
              </div>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.copyInputSample()}
                style={{marginLeft: 10}}
              >
                コピー
              </Button>
            </div>
          </li>
        </ul>

        <div style={{marginLeft: 20}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        {this.state.showInput ?
          <SimpleTable
            records={this.state.records}
            columns={this.state.columns}
            height={400}
          />
          : null
        }
      </div>
    );
  }
}

CowDeleteIndex.propTypes = {
  classes: PropTypes.object.isRequired,
};

const forExport = withStyles(styles)(CowDeleteIndex);
export {forExport as CowDeleteIndex};
