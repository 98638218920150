import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {cowGroupAPI} from 'api/cow-group.js';
import {CowGroup} from 'models/cow-group.js';
import {DateUtil} from 'utils/date-util.js';
import {ViewMode} from 'models/view-mode.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ActionTable} from 'components/tables/ActionTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class CowGroupIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'cowGroupId', label: '牛群ID'},
      {id: 'cowGroupName', label: '牛群名'},
      {id: 'cowshedName', label: '牛舎名'},
      {id: 'groupAttribute', label: '牛群属性'},
      {id: 'pens', label: '牛房'},
      {id: 'menuDisplayed', label: 'ナビ表示', type: 'boolean'},
      {id: 'cowCount', label: '牛個体数'},
      {id: 'updatedAtDisaply', label: '最新更新日時'},
    ];

    this.state = {
      records: [],
      columns: columns,
      showProgress: false
    };
  }

  componentDidMount() {
    this.index();
  }

  createAction() {
    const params = {
      pathname: '/cow_group/create',
    };
    this.props.history.push(params);
  }

  updateAction(record) {
    const params = {
      pathname: `/cow_group/${record.cowGroupId}/${ViewMode.UPDATE}`,
    };
    this.props.history.push(params);
  }

  deleteAction(record) {
    const params = {
      pathname: `/cow_group/${record.cowGroupId}/${ViewMode.DELETE}`,
    };
    this.props.history.push(params);
  }

  index() {
    this.setState({showProgress: true});

    return cowGroupAPI.index(sessionState.currentFarmId()).then((res) => {
      const records = res.data.map((r) => {
        const groupAttribute = CowGroup.selectedGroupAttribute(r);
        const defaultGroupAttribute = r.calfDefaultGroup || r.cullingDefaultGroup;

        r.groupAttribute = groupAttribute.label;
        r.updatedAtDisaply = DateUtil.toYYYYMMDDHHmm(r.updatedAt);
        r.canUpdate = true;
        r.canDelete = r.cowCount === 0 && !defaultGroupAttribute;
        return r;
      });

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>牛群</h2>

        <ul className={classes.flexContainer}>
          <li>
            <FarmSelect changeAction={() => this.index()} />
          </li>

          <li style={{marginLeft: '100px'}}>
            <Button variant="contained" color="primary" onClick={() => this.createAction()}>
              登録
            </Button>
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <ActionTable
          records={this.state.records}
          columns={this.state.columns}
          updateAction={(record) => this.updateAction(record)}
          deleteAction={(record) => this.deleteAction(record)}
          linkColumnId="cowGroupName"
        />
      </div>
    );
  }
}

CowGroupIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(CowGroupIndex));
export {forExport as CowGroupIndex};
