import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

import {Notification} from 'models/notification';
import {DateTimePickerWrapper} from 'components/DateTimePickerWrapper.js';

const styles = {
  inputBox: {
    marginBottom: '20px',
  },
  textField: {
    width: 400,
  },
  selectField: {
    minWidth: 200,
  },
  dateTimeField: {
    width: 180,
  },
  textAreaField: {
    width: 600,
  },
  multiField: {
    marginRight: 40,
  },
};

class NotificationInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.record);
    this.syncState = props.syncState;
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
    this.syncState(key, e.target.value);
  }

  onChangeDate(key, value) {
    this.setState(() => ({[key]: value}));
    this.syncState(key, value);
  }

  render() {
    const classes = this.props.classes;

    const farmTypeOptions = [];
    Notification.farmTypes.forEach((v, k) => {
      farmTypeOptions.push(<MenuItem key={k} value={k}>{v}</MenuItem>);
    });

    const categoryOptions = [];
    Notification.categories.forEach((v, k) => {
      categoryOptions.push(<MenuItem key={k} value={k}>{v}</MenuItem>);
    });

    return (
      <Box style={{marginLeft: '100px'}}>
        <form>
          <Box className={classes.inputBox}>
            <FormControl className={clsx(classes.dateTimeField, classes.multiField)} required>
              <InputLabel htmlFor="farm-type">牧場種別</InputLabel>
              <Select
                value={this.state.farmType}
                onChange={(e) => this.onChangeInput(e, 'farmType')}
                input={<Input id="farm-type" />}
                style={{minWidth: '150px'}}
              >
                {farmTypeOptions}
              </Select>
            </FormControl>

            <FormControl className={classes.selectField} required>
              <InputLabel htmlFor="contracted-plan">契約プラン</InputLabel>
              <Select
                value={this.state.contractedPlan}
                onChange={(e) => this.onChangeInput(e, 'contractedPlan')}
                input={<Input id="contracted-plan" />}
                style={{minWidth: '150px'}}
              >
                <MenuItem value={'ALL'}>ALL</MenuItem>
                <MenuItem value={'FULL'}>FULL</MenuItem>
                <MenuItem value={'LIGHT'}>LIGHT</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box className={classes.inputBox}>
            <FormControl className={clsx(classes.dateTimeField, classes.multiField)} required>
              <DateTimePickerWrapper
                label="表示開始時刻"
                datetime={this.state.startAt}
                syncState={(value) => this.onChangeDate('startAt', value)}
              />
            </FormControl>

            <FormControl className={classes.dateTimeField}>
              <DateTimePickerWrapper
                label="表示終了時刻"
                datetime={this.state.endAt}
                syncState={(value) => this.onChangeDate('endAt', value)}
              />
            </FormControl>
          </Box>

          <Box className={classes.inputBox}>
            <FormControl className={clsx(classes.selectField, classes.multiField)}>
              <InputLabel htmlFor="category">カテゴリー</InputLabel>
              <Select
                value={this.state.category}
                onChange={(e) => this.onChangeInput(e, 'category')}
                input={<Input id="category" />}
                style={{minWidth: '150px'}}
              >
                {categoryOptions}
              </Select>
            </FormControl>

            <FormControl className={classes.selectField} required>
              <InputLabel htmlFor="priority">優先度</InputLabel>
              <Select
                value={this.state.priority}
                onChange={(e) => this.onChangeInput(e, 'priority')}
                input={<Input id="priority" />}
                style={{minWidth: '150px'}}
              >
                <MenuItem value={'0'}>0</MenuItem>
                <MenuItem value={'1'}>1</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box className={classes.inputBox}>
            <FormControl>
              <TextField
                type="text"
                placeholder=""
                label="タイトル"
                value={this.state.title}
                onChange={(e) => this.onChangeInput(e, 'title')}
                margin="none"
                className={classes.textField}
                inputProps={{maxLength: 40}}
                required
                error={!this.state.title}
              />
            </FormControl>
          </Box>

          <Box className={classes.inputBox}>
            <FormControl>
              <TextField
                type="text"
                placeholder=""
                label="お知らせ内容"
                value={this.state.content}
                onChange={(e) => this.onChangeInput(e, 'content')}
                margin="none"
                className={classes.textAreaField}
                multiline
                rows={15}
                inputProps={{maxLength: 2000}}
                required
                error={!this.state.content}
              />
            </FormControl>
          </Box>
        </form>
      </Box>
    );
  }
}

NotificationInput.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
};

const forExport = withStyles(styles)(NotificationInput);
export {forExport as NotificationInput};
