import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';

import {authorizedFarmAPI} from 'api/authorized-farm.js';
import {ErrorUtil} from 'utils/error-util.js';

import {ReturnButton} from 'components/ReturnButton.js';
import {AuthorizedFarmInput} from 'components/forms/authorized-farm/AuthorizedFarmInput.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class AuthorizedFarmEdit extends React.Component {
  constructor(props) {
    super();

    const params = props.match.params;
    this.accountId = parseInt(params.id, 10);

    this.title = '牧場アクセス権 - 編集';
    this.notification = '更新が完了しました';

    this.state = {
      record: {},
      loaded: false,
      showProgress: false,
      showDialog: false,
    };
  }

  componentDidMount() {
    this.show();
  }

  show() {
    this.setState({showProgress: true});

    return authorizedFarmAPI.show(this.accountId).then((res) => {
      const record = Object.assign({}, res.data);

      this.setState({
        record: record,
        loaded: true,
        showProgress: false
      });
    });
  }

  returnAction() {
    this.props.history.push('/authorized_farm');
  }

  syncState(key, value) {
    const input = this.state.input || {...this.state.record};
    input[key] = value;
    this.setState({input: input});
  }

  validate() {
    const input = this.state.input;
    const errors = [];

    if (!input) {
      errors.push('内容が変更されていません');
      return {valid: false, errors: errors};
    }

    if (input.farms.length === 0) {
      errors.push('牧場が選択されていません');
      return {valid: false, errors: errors};
    }

    return {valid: errors.length === 0, errors: errors};
  }

  save() {
    const result = this.validate();
    if (!result.valid) {
      this.setState({errorMessage: result.errors.join('\n')});
      return;
    } else {
      this.setState({errorMessage: ''});
    }

    this.setState({showProgress: true});

    authorizedFarmAPI.create(this.createUpdateParams()).then((res) => {
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({showDialog: false});
        this.returnAction();
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({errorMessage: errorMessage});
      console.error(errorMessage);
    });
  }

  createUpdateParams() {
    const input = this.state.input;
    return {
      accountId: this.accountId,
      farmIds: input.farms.map((f) => f.farmId),
    };
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>{this.title}</h2>

        <ul className={classes.flexContainer}>
          <li>
            <ReturnButton returnAction={() => this.returnAction()} />
          </li>

          <li style={{marginLeft: '500px'}}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.save()}
              disabled={this.state.showProgress}>
              更新
            </Button>
          </li>
        </ul>

        <div style={{marginLeft: '40px'}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <DetailForm
          loaded={this.state.loaded}
          record={this.state.record}
          state={this.state}
          syncState={this.syncState.bind(this)}
        />
      </div>
    );
  }
}

AuthorizedFarmEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(AuthorizedFarmEdit));
export {forExport as AuthorizedFarmEdit};

const DetailForm = (props) => {
  if (props.loaded) {
    return (
      <AuthorizedFarmInput
        record={props.record}
        syncState={props.syncState}
      />
    );
  } else {
    return null;
  }
};

DetailForm.propTypes = {
  loaded: PropTypes.bool.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
};
