import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import 'moment/locale/ja';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';

const styles = {};

class DateTimePickerWrapper extends React.Component {
  constructor(props) {
    super();

    this.label = props.label;
    this.syncState = props.syncState;

    this.state = {
      datetime: props.datetime || null
    };
  }

  onChangeDate(value) {
    this.setState(() => ({
      datetime: value
    }));
    this.syncState(value);
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale='ja'>
        <KeyboardDateTimePicker
          disableToolbar
          variant='inline'
          ampm={false}
          format='YYYY/MM/DD HH:mm'
          margin='none'
          id='date-time-picker-wrapper'
          label={this.label}
          value={this.state.datetime}
          onChange={(value) => this.onChangeDate(value)}
          KeyboardButtonProps={{
            'aria-label': 'change datetime',
          }}
          invalidDateMessage='日付の形式に誤りがあります'
          fullWidth
          minutesStep={5}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

DateTimePickerWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  datetime: PropTypes.object,
  syncState: PropTypes.func.isRequired,
};

const forExport = withStyles(styles)(DateTimePickerWrapper);
export {forExport as DateTimePickerWrapper};
