import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import {GlobalCss} from 'components/global-css.js';

const styles = makeStyles({
  readonlyText: GlobalCss.readonlyText,
});

export const ReadOnlyText = (props) => {
  const classes = styles();
  return (
    <TextField
      type="text"
      label={props.label}
      value={props.value}
      multiline={props.multiline || false}
      style={props.style || {}}
      InputProps={{
        readOnly: true,
      }}
      variant="outlined"
      margin="none"
      className={classes.readonlyText}
    />
  );
};

ReadOnlyText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  style: PropTypes.object,
};

