import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {GlobalCss} from 'components/global-css.js';

const styles = {
  inputArea: {
    marginBottom: '30px',
  },
  textField: {
    width: 300,
    marginBottom: '20px',
  },
  readonlyText: GlobalCss.readonlyText
};

class AccountInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.record);
    this.syncState = props.syncState;
    this.roles = props.roles;
    this.isCreate = props.isCreate;
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
    this.syncState(key, e.target.value);
  }

  onChangeCheckbox(e, key) {
    this.setState({[key]: e.target.checked});
    this.syncState(key, e.target.checked);
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '100px'}}>
        <InputForm
          classes={classes}
          state={this.state}
          roles={this.roles}
          isCreate={this.isCreate}
          onChangeInput={this.onChangeInput.bind(this)}
          onChangeCheckbox={this.onChangeCheckbox.bind(this)}
        />
      </div>
    );
  }
}

AccountInput.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
  isCreate: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
};

const forExport = withStyles(styles)(AccountInput);
export {forExport as AccountInput};

const InputForm = (props) => {
  if (props.isCreate) {
    return (
      <CreateForm
        classes={props.classes}
        state={props.state}
        roles={props.roles}
        onChangeInput={props.onChangeInput}
        onChangeCheckbox={props.onChangeCheckbox}
      />
    );
  } else {
    return (
      <UpdateForm
        classes={props.classes}
        state={props.state}
        roles={props.roles}
        onChangeInput={props.onChangeInput}
        onChangeCheckbox={props.onChangeCheckbox}
      />
    );
  }
};

const CreateForm = (props) => {
  const classes = props.classes;
  const state = props.state;
  const roles = props.roles;
  const onChangeInput = props.onChangeInput;
  const onChangeCheckbox = props.onChangeCheckbox;

  return (
    <form className={classes.inputArea} autoComplete="off">
      <div>
        <TextField
          type="text" label="ユーザー名"
          value={state.name} onChange={(e) => onChangeInput(e, 'name')}
          margin="none" required
          className={classes.textField}
        />
      </div>

      <div>
        <TextField
          type="email" label="メールアドレス"
          value={state.email} onChange={(e) => onChangeInput(e, 'email')}
          margin="none" required
          className={classes.textField}
        />
      </div>

      <div>
        <TextField
          type="password" label="パスワード"
          value={state.password} onChange={(e) => onChangeInput(e, 'password')}
          margin="none" required
          className={classes.textField}
          autoComplete="new-password"
        />
      </div>

      <div>
        <TextField
          type="password" label="パスワード(確認用)"
          value={state.passwordConfirm} onChange={(e) => onChangeInput(e, 'passwordConfirm')}
          margin="none" required
          className={classes.textField}
          autoComplete="new-password"
        />
      </div>

      <div className={classes.textField}>
        <InputLabel htmlFor="role" style={{fontSize: '12px'}}>役割</InputLabel>
        <Select
          value={state.role}
          onChange={(e) => onChangeInput(e, 'role')}
          input={<Input id="role" />}
          style={{minWidth: '150px'}}
        >
          {roles.map((role, index) => {
            return (
              <MenuItem key={index} value={role}>{role}</MenuItem>
            );
          })}
        </Select>
      </div>

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.shared}
              onChange={(e) => onChangeCheckbox(e, 'shared')}
              color="primary"
            />
          }
          label="共有アカウント"
          style={{marginRight: 30}}
        />
      </div>
    </form>
  );
};

CreateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
};

const UpdateForm = (props) => {
  const classes = props.classes;
  const state = props.state;
  const onChangeInput = props.onChangeInput;
  const onChangeCheckbox = props.onChangeCheckbox;
  const roles = props.roles;

  return (
    <form className={classes.inputArea} autoComplete="off">
      <div>
        <TextField
          type="text" label="ユーザー名"
          value={state.name} onChange={(e) => onChangeInput(e, 'name')}
          margin="none" required
          className={classes.textField}
        />
      </div>

      <div className={classes.textField}>
        <InputLabel htmlFor="role" style={{fontSize: '12px'}}>役割</InputLabel>
        <Select
          value={state.role}
          onChange={(e) => onChangeInput(e, 'role')}
          input={<Input id="role" />}
          style={{minWidth: '150px'}}
        >
          {roles.map((role, index) => {
            return (
              <MenuItem key={index} value={role}>{role}</MenuItem>
            );
          })}
        </Select>
      </div>

      <div style={{marginBottom: 30}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.shared}
              onChange={(e) => onChangeCheckbox(e, 'shared')}
              color="primary"
            />
          }
          label="共有アカウント"
          style={{marginRight: 30}}
        />
      </div>

      <div>
        <TextField
          type="email" label="メールアドレス"
          value={state.email}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          margin="none"
          className={classes.readonlyText}
          style={{marginRight: 20}}
        />

        <TextField
          type="text" label="uid"
          value={state.uid}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          margin="none"
          className={classes.readonlyText}
        />
      </div>
    </form>
  );
};

UpdateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
};
