export class ErrorUtil {
  /**
   * APIのレスポンスからエラーメッセージを生成します。
   * レスポンスにエラーメッセージが含まれていればそれを整形して返します。
   *
   * @param {Object} res APIのレスポンス
   * @return {string} 改行整形されたエラーメッセージ
  */
  static generateMessage(res) {
    if (res.data && res.data.messages) {
      return ErrorUtil.formatErrorMessage(res.data.messages);
    }
    return 'サーバーへの接続に失敗しました';
  }

  /**
   * APIのエラーメッセージを改行整形して返します。
   *
   * @param {Array.<Object>} messages APIのエラーメッセージ
   * @return {string} 改行整形されたエラーメッセージ
   *
   * ex.
   * [{field: 'name', message: '既に使用されている名称です。'},
   *  {field: 'startDate', message: '履歴が重複しています。'}]
   * ⇒ '既に使用されている名称です。\n履歴が重複しています。'
  */
  static formatErrorMessage(messages) {
    return messages.map((m) => {
      let message = m.message;
      if (m.lineNo) {
        message = `${m.lineNo}行目 - ${message}`;
      }
      return message;
    }).join('\n');
  }

  /**
   * APIの一括用のエラーメッセージを改行整形して返します。
   *
   * @param {Array.<Object>} messages APIのエラーメッセージ
   * @return {string} 改行整形されたエラーメッセージ
   *
   * ex.
   * [{field: 'name', lineNo: 1, message: '既に使用されている名称です。'},
   *  {field: 'startDate', lineNo: 1, message: '履歴が重複しています。'},
   *  {field: 'startDate', lineNo:2, message: '履歴が重複しています。'}]
   * ⇒ '1行目:既に使用されている名称です。履歴が重複しています。\n2行目:履歴が重複しています。'
  */
  static formatMultilineErrorMessage(messages) {
    const errors = {};

    messages.forEach((m) => {
      errors[m.lineNo] = errors[m.lineNo] || '';
      errors[m.lineNo] += m.message;
    });

    return Object.keys(errors).sort((a, b) => a - b).map((lineNo) => {
      return `${lineNo}行目： ${errors[lineNo]}`;
    }).join('\n');
  }
}
