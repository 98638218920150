import {apiBase} from 'api/base.js';

class SloppyReportAPI {
  index(farmId) {
    return apiBase.get(`/admin/sloppy_report?farm_id=${farmId}`);
  }

  show(farmId, id) {
    return apiBase.get(`/admin/sloppy_report/${id}?farm_id=${farmId}`);
  }

  create(farmId, params) {
    params.farmId = farmId;
    return apiBase.post('/admin/sloppy_report', params);
  }

  update(farmId, id, params) {
    params.farmId = farmId;
    return apiBase.put(`/admin/sloppy_report/${id}`, params);
  }

  delete(farmId, id) {
    const params = {farmId: farmId};
    return apiBase.delete(`/admin/sloppy_report/${id}`, params);
  }

  confirmSql(farmId, sql) {
    const params = {farmId: farmId, sql: sql};
    return apiBase.post('/admin/sloppy_report/confirm_sql', params);
  }
}

export const sloppyReportAPI = new SloppyReportAPI();
