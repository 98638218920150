import {apiBase} from 'api/base.js';

class AccountAPI {
  index(farmId) {
    return apiBase.get(`/admin/account?farm_id=${farmId}`);
  }

  show(farmId, id) {
    return apiBase.get(`/admin/account/${id}?farm_id=${farmId}`);
  }

  create(farmId, params) {
    return apiBase.post('/admin/account', params);
  }

  update(id, params) {
    return apiBase.put(`/admin/account/${id}`, params);
  }

  delete(farmId, id) {
    const params = {farmId: farmId};
    return apiBase.delete(`/admin/account/${id}`, params);
  }
}

export const accountAPI = new AccountAPI();
