export class CowGroup {
  static get allGroupAttributes() {
    return [
      {key: 'calfGroup', label: '育成牛群', farmType: 'ALL'},
      {key: 'reproductionGroup', label: '繁殖牛群', farmType: 'ALL'},
      {key: 'cullingGroup', label: '出荷へい死牛群', farmType: 'ALL'},
      {key: 'milkingGroup', label: '搾乳牛群', farmType: 'MILK'},
      {key: 'dryGroup', label: '乾乳牛群', farmType: 'MILK'},
      {key: 'fatteningGroup', label: '肥育牛群', farmType: 'BEEF'},
    ];
  }

  static groupAttributes(isMilkFarm) {
    if (isMilkFarm) {
      return CowGroup.allGroupAttributes.filter((a) => {
        return a.farmType === 'MILK' || a.farmType === 'ALL';
      });
    } else {
      return CowGroup.allGroupAttributes.filter((a) => {
        return a.farmType === 'BEEF' || a.farmType === 'ALL';
      });
    }
  }

  static selectedGroupAttribute(cowGroup) {
    const attribute = CowGroup.allGroupAttributes.find((a) => cowGroup[a.key]);
    if (!attribute) {
      return {label: '-'};
    }
    return attribute;
  }
}
