export class MasterImport {
  static get importColumnDefinitions() {
    return {
      'cowshed': [
        {label: '牛舎名', value: '牛舎A'},
        {label: '新採食判定の利用開始日', value: '2019/10/1'},
        {label: '飲水の取得元', value: 'mimosa'},
        {label: '発情指数算出ロジック', value: 'SLICE3H'},
        {label: 'センサー番号1', value: ''},
        {label: 'センサー番号2', value: ''},
        {label: 'センサー番号3', value: ''},
        {label: 'センサー番号4', value: ''},
        {label: 'センサー番号5', value: ''},
      ],
      'cow_group': [
        {label: '牛群名', value: '牛群1'},
        {label: '牛舎名', value: '牛舎A'},
        {label: '牛群属性', value: '繁殖牛群'},
        {label: 'ナビゲーション表示', value: 'true'},
        {label: '牛房', value: '1'},
        {label: '※以降は列キャプションは不要', value: '2'},
        {label: '※牛房がなければ不要な列', value: '3'},
      ],
      'master_medicine': [
        {label: '薬品名', value: 'マストリチン'},
        {label: '単位', value: 'ml'},
        {label: '標準処置方法', value: '経口投与'},
        {label: '牛乳休薬期間', value: '1.0'},
        {label: '食肉休薬期間', value: '2.0'},
        {label: 'ワクチン', value: '〇'},
        {label: '乳房炎', value: '〇'},
        {label: '治療全般', value: '〇'},
      ],
      'master_disease': [
        {label: '病名・症状', value: '発熱'},
        {label: '備考', value: '備考記録'},
      ],
      'master_sperm': [
        {label: '精液番号', value: '黒原4899'},
        {label: '種雄牛名', value: '花国安福'},
        {label: '品種', value: '黒毛和種'},
        {label: '登記番号', value: '黒原4899'},
        {label: '略号', value: 'HKY'},
        {label: '育種価', value: 'HHHBAB'},
      ],
      'master_market': [
        {label: '名前', value: '指宿'},
        {label: '住所', value: '鹿児島県指宿市9876'},
        {label: '電話番号', value: '1234-56-7890'},
        {label: 'FAX番号', value: '7890-12-3456'},
      ],
    };
  }

  static importColumns(masterId) {
    return MasterImport.importColumnDefinitions[masterId] || [];
  }
}
