export class ActionLog {
  static get dataTypes() {
    return [
      {dataType: 'cow_event', label: 'イベント'},
      {dataType: 'cowshed', label: '牛舎'},
      {dataType: 'cow_group', label: '牛群'},
      {dataType: 'master_sperm', label: '精液'},
      {dataType: 'master_medicine', label: '薬品'},
      {dataType: 'master_disease', label: '病名・症状'},
      {dataType: 'master_market', label: '市場'},
    ];
  }

  static get masterDataTypes() {
    return ActionLog.dataTypes.filter((e) => e.dataType !== 'cow_event');
  }

  static get notUpdatableDataTypes() {
    return [
      'activity_import_retry',
      'eartag_analyzer_retry',
      'ecs_task_execute'
    ];
  }

  static dataTypeLabel(dataType) {
    switch (dataType) {
    case 'cow_event':
      return 'イベント';
    case 'cowshed':
      return '牛舎';
    case 'cow_group':
      return '牛群';
    case 'master_sperm':
      return '精液';
    case 'master_medicine':
      return '薬品';
    case 'master_disease':
      return '病名・症状';
    case 'master_market':
      return '市場';
    case 'cow_delete':
      return '牛個体';
    case 'farm_moving':
      return '牧場異動';
    case 'calf_delete':
      return '子牛';
    case 'activity_import_retry':
      return '活動量再取込';
    case 'eartag_analyzer_retry':
      return '[イヤタグ] 活動量再分析';
    case 'ecs_task_execute':
      return 'ECSタスク実行';
    default:
      return '';
    }
  }

  static actionTypeLabel(actionType) {
    switch (actionType) {
    case 'insert':
      return '登録';
    case 'update':
      return '更新';
    case 'delete':
      return '削除';
    default:
      return '';
    }
  }

  static canUpdate(dataType) {
    if (this.notUpdatableDataTypes.includes(dataType)) {
      return false;
    }

    return true;
  }
}
