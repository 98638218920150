export class GlobalCss {
  static get flexContainer() {
    return {
      display: 'flex',
      listStyle: 'none'
    };
  }

  static get leftJustifiedIitem() {
    return {
      marginRight: 'auto'
    };
  }

  static get rightJustifiedIitem() {
    return {
      marginLeft: 'auto'
    };
  }

  static get readonlyText() {
    return {
      width: 300,
      backgroundColor: '#f5f5f5',
    };
  }

  static get enclosedArea() {
    return {
      display: 'inline-block',
      borderRadius: 3,
      border: '1px dashed #888',
      padding: 8,
    };
  }
}
