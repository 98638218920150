import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {sessionState} from 'session/session-state.js';

const styles = {
  flexContainer: {
    display: 'flex',
    listStyle: 'none'
  },
};

class FarmSelect extends React.Component {
  constructor(props) {
    super();

    this.changeAction = props.changeAction;
    const filterFunction = props.filterFunction;

    let farms = sessionState.farms() || [];
    if (filterFunction) {
      farms = filterFunction(farms);
    }
    this.farmOptions = farms.map((farm) => {
      return {value: farm.id(), label: farm.farmName()};
    });

    const farmId = sessionState.currentFarmId();
    const selected = this.farmOptions.find((e) => e.value === farmId) || this.farmOptions[0];
    this.state = {
      selected: selected
    };
  }

  onChangeSelect(option) {
    this.setState({selected: option});
    sessionState.changeFarmId(option.value);

    if (this.changeAction) {
      this.changeAction();
    }
  }

  render() {
    const classes = this.props.classes;
    const isDisabled = this.props.isDisabled || false;

    return (
      <div style={{marginBottom: 10}}>
        <ul className={classes.flexContainer}>
          <li>
            <div style={{paddingTop: 8, width: 50, fontWeight: 'bold'}}>牧場</div>
          </li>

          <li>
            <div style={{width: 500}}>
              <Select
                options={this.farmOptions}
                value={this.state.selected}
                onChange={(option) => this.onChangeSelect(option)}
                TextFieldProps={{
                  label: 'Country',
                  InputLabelProps: {
                    htmlFor: 'react-select-single',
                    shrink: true,
                  },
                }}
                isDisabled={isDisabled}
              />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

FarmSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  changeAction: PropTypes.func,
  filterFunction: PropTypes.func,
  isDisabled: PropTypes.bool
};

const forExport = withStyles(styles)(FarmSelect);
export {forExport as FarmSelect};
