import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';

import {calfDeleteAPI} from 'api/calf-delete.js';
import {Cow} from 'models/cow.js';
import {cowGroupAPI} from 'api/cow-group.js';
import {DateUtil} from 'utils/date-util.js';
import {DatePickerWrapper} from 'components/DatePickerWrapper.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {ErrorUtil} from 'utils/error-util.js';
import {FarmSelect} from 'components/FarmSelect.js';
import {GlobalCss} from 'components/global-css.js';
import {masterCowLabelAPI} from 'api/master-cow-label.js';
import SelectionTable from 'components/tables/SelectionTable.js';
import {sessionState} from 'session/session-state.js';


const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  selectField: {
    minWidth: 100,
  },
  longTextField: {
    width: 500,
  },
  enclosedArea: GlobalCss.enclosedArea,
};

class CalfDeleteIndex extends React.Component {
  constructor() {
    super();

    this.state = {
      canDelete: false,
      condition: {
        cowGroupId: '',
        cowUid: '',
        cowNo: '',
        birthStartDate: null,
        birthEndDate: null,
        gender: '',
        breed: '',
        state: '',
        cowLabel: ''
      },
      cowGroupOptions: [],
      cowLabelOptions: [],
      cowIds: [],
      errorMessage: '',
      notes: '',
      rows: [],
      showDialog: false,
      showProgress: false
    };
    this.setTableHeader();
    this.setSelectionOptions();
  }

  componentDidMount() {
    this.onFarmChange();
  }

  setTableHeader() {
    this.tableColumns = [
      {id: 'cowId', label: 'cow_id'},
      {id: 'cowGroupName', label: '牛群名'},
      {id: 'cowNo', label: '牛番号'},
      {id: 'cowUid', label: '個体識別番号'},
      {id: 'formattedBirthday', label: '出生日'},
      {id: 'gender', label: '性別'},
      {id: 'breed', label: '品種'},
      {id: 'state', label: '状態'},
      {id: 'cowLabels', label: '個体ラベル'},
      {id: 'motherCowUid', label: '母牛の個体識別番号'},
      {id: 'recipientCowUid', label: '受卵母牛の個体識別番号'}
    ];
  }

  setSelectionOptions() {
    this.genderOptions = [
      {value: '', label: ''},
      ...Cow.GENDER_OPTIONS
    ];
    this.breedOptions = [
      {value: '', label: ''},
      ...Cow.BREED_OPTIONS
    ];
    this.stateOptions = [
      {value: '', label: ''},
      ...Cow.STATE_OPTIONS
    ];
  }

  setCowIds(cowIds) {
    this.setState({cowIds: cowIds});
  }

  clearState() {
    this.setState((prevState) => {
      const state = {
        ...prevState,
        condition: {
          cowGroupId: '',
          cowUid: '',
          cowNo: '',
          birthStartDate: null,
          birthEndDate: null,
          gender: '',
          breed: '',
          state: '',
          cowLabel: ''
        },
        cowIds: [],
        errorMessage: '',
        rows: []
      };
      return state;
    });
  }

  setCowGroupOptions() {
    cowGroupAPI.index(sessionState.currentFarmId()).then((res) => {
      const cowGroupOptions = res.data.map((r) => {
        return {value: r.cowGroupId, label: r.cowGroupName, pens: r.pens || ''};
      });

      cowGroupOptions.unshift({value: '', label: '', pens: ''});
      this.setState({cowGroupOptions});
    });
  }

  setCowLabelOptions() {
    masterCowLabelAPI.available(sessionState.currentFarmId()).then((res) => {
      const cowLabelOptions = res.data.map((record) => {
        return {
          value: record.id,
          label: record.name
        };
      });
      cowLabelOptions.unshift({value: '', label: ''});
      this.setState({cowLabelOptions});
    });
  }

  onConditionChange(e, key) {
    this.setState({condition: {...this.state.condition, [key]: e.target.value}});
  }

  onConditionDateChange(value, key) {
    this.setState({condition: {...this.state.condition, [key]: value}});
  }

  onNotesInput(e) {
    this.setState({notes: e.target.value});
  }

  onSearchClick() {
    this.setState({showProgress: true});

    const condition = {
      farmId: sessionState.currentFarmId(),
      cowGroupId: this.state.condition.cowGroupId,
      cowUid: this.state.condition.cowUid,
      cowNo: this.state.condition.cowNo,
      birthStartDate: DateUtil.toMSec(this.state.condition.birthStartDate),
      birthEndDate: DateUtil.toMSec(this.state.condition.birthEndDate),
      gender: this.state.condition.gender,
      breed: this.state.condition.breed,
      state: this.state.condition.state,
      cowLabel: this.state.condition.cowLabel
    };

    return calfDeleteAPI.candidates(condition).then((res) => {
      const rows = res.data.map((r, index) => {
        r.id = r.cowId;
        r.formattedBirthday = DateUtil.toYYYYMMDD(r.birthday);
        return r;
      });
      this.setState({rows: rows});
    }).finally(() => {
      this.setState({showProgress: false});
    });
  }

  onFarmChange() {
    this.setState({showProgress: true});
    this.clearState();
    Promise.all([
      this.setCowGroupOptions(),
      this.setCowLabelOptions()
    ]).finally(() => {
      this.setState({showProgress: false});
    });
  }

  onDeleteClick() {
    const params = {
      accountId: sessionState.accountId(),
      farmId: sessionState.currentFarmId(),
      cowIds: this.state.cowIds,
      notes: this.state.notes
    };
    this.setState({showProgress: true});

    calfDeleteAPI.create(params).then((res) => {
      this.notification = '削除が完了しました';
      this.setState({showDialog: true, showProgress: false});
      this.clearState();
      setTimeout(() => {
        this.setState({
          showDialog: false,
          canDelete: false
        });
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({
        errorMessage: errorMessage,
        canExecute: false,
      });
      console.error(errorMessage);
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>子牛一括削除</h2>

        <ul className={classes.flexContainer}>
          <li style={{marginRight: 50}}>
            <FarmSelect
              changeAction={() => this.onFarmChange()} />
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <ul className={classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
          <li style={{marginRight: 30}}>
            <InputLabel htmlFor="cow-group-id" style={{fontSize: 12}}>牛群名</InputLabel>
            <Select
              className={classes.selectField}
              value={this.state.condition.cowGroupId}
              onChange={(e) => this.onConditionChange(e, 'cowGroupId')}
              inputProps={{name: 'cowGroupId', id: 'cow-group-id'}}
            >
              {this.state.cowGroupOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                );
              })}
            </Select>
          </li>

          <li style={{marginRight: 30}}>
            <InputLabel htmlFor="gender" style={{fontSize: 12}}>性別</InputLabel>
            <Select
              className={classes.selectField}
              value={this.state.condition.gender}
              onChange={(e) => this.onConditionChange(e, 'gender')}
              inputProps={{name: 'gender', id: 'gender'}}
            >
              {this.genderOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                );
              })}
            </Select>
          </li>

          <li style={{marginRight: 30}}>
            <InputLabel htmlFor="breed" style={{fontSize: 12}}>品種</InputLabel>
            <Select
              className={classes.selectField}
              value={this.state.condition.breed}
              onChange={(e) => this.onConditionChange(e, 'breed')}
              inputProps={{name: 'breed', id: 'breed'}}
            >
              {this.breedOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                );
              })}
            </Select>
          </li>

          <li style={{marginRight: 30}}>
            <InputLabel htmlFor="state" style={{fontSize: 12}}>状態</InputLabel>
            <Select
              className={classes.selectField}
              value={this.state.condition.state}
              onChange={(e) => this.onConditionChange(e, 'state')}
              inputProps={{name: 'state', id: 'state'}}
            >
              {this.stateOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                );
              })}
            </Select>
          </li>

          <li style={{marginRight: 30}}>
            <InputLabel htmlFor="cow-label" style={{fontSize: 12}}>個体ラベル</InputLabel>
            <Select
              className={classes.selectField}
              value={this.state.condition.cowLabel}
              onChange={(e) => this.onConditionChange(e, 'cowLabel')}
              inputProps={{name: 'cowLabel', id: 'cow-label'}}
            >
              {this.state.cowLabelOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.label}>{option.label}</MenuItem>
                );
              })}
            </Select>
          </li>
        </ul>
        <ul className={classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
          <li style={{width: 120, marginRight: 10}}>
            <TextField
              type="text" margin="none"
              label="牛番号" value={this.state.condition.cowNo}
              onChange={(e) => this.onConditionChange(e, 'cowNo')}
              inputProps={{maxLength: 40}}
            />
          </li>

          <li style={{width: 120, marginRight: 10}}>
            <TextField
              type="text" margin="none"
              label="個体識別番号" value={this.state.condition.cowUid}
              onChange={(e) => this.onConditionChange(e, 'cowUid')}
              inputProps={{maxLength: 10}}
            />
          </li>

          <li style={{width: 200, marginRight: 10}}>
            <DatePickerWrapper
              label="出生日(検索開始日)"
              date={this.state.condition.birthStartDate}
              syncState={(value) => this.onConditionDateChange(value, 'birthStartDate')}
            />
          </li>

          <li style={{width: 180, marginRight: 10}}>
            <DatePickerWrapper
              label="出生日(検索終了日)"
              date={this.state.condition.birthEndDate}
              syncState={(value) => this.onConditionDateChange(value, 'birthEndDate')}
            />
          </li>

          <li>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.onSearchClick()}
            >
              検索
            </Button>
          </li>
        </ul>

        <div style={{marginLeft: 20}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        {this.state.rows.length ?
          <>
            <SelectionTable
              title="検索結果"
              rows={this.state.rows}
              columns={this.tableColumns}
              onSelect={(ids) => this.setCowIds(ids)} />

            <ul className={classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
              <li style={{width: 50, marginTop: 10}}>
                <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>備考</InputLabel>
              </li>

              <li style={{marginRight: 30}}>
                <TextField
                  type="text" margin="dense" style={{width: 620}}
                  value={this.state.notes}
                  variant="outlined" multiline={true} rows={2}
                  onChange={(e) => this.onNotesInput(e)}
                />
              </li>

              <li style={{width: 140}}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.onDeleteClick()}
                  disabled={!this.state.cowIds.length}
                >
                  削除
                </Button>
              </li>
            </ul>
          </> : null
        }
      </div>
    );
  }
}

CalfDeleteIndex.propTypes = {
  classes: PropTypes.object.isRequired,
};

const forExport = withStyles(styles)(CalfDeleteIndex);
export {forExport as CalfDeleteIndex};
