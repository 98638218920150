import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {sessionState} from 'session/session-state.js';
import {cowGroupAPI} from 'api/cow-group.js';
import {masterCowLabelAPI} from 'api/master-cow-label.js';
import {farmMovingAPI} from 'api/farm-moving.js';
import {depositFarmRelationAPI} from 'api/deposit-farm-relation.js';
import {StringUtil} from 'utils/string-util.js';
import {ErrorUtil} from 'utils/error-util.js';
import {DateUtil} from 'utils/date-util.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {GlobalCss} from 'components/global-css.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {DatePickerWrapper} from 'components/DatePickerWrapper.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  selectField: {
    minWidth: 100,
  },
  longTextField: {
    width: 500,
  },
  enclosedArea: GlobalCss.enclosedArea,
};

class FarmMovingIndex extends React.Component {
  constructor() {
    super();

    const farms = sessionState.farms() || [];
    this.agentFarms = farms.filter((farm) => farm.isAgent());

    const agentFarmSelected = this.agentFarms.some((farm) => farm.id() === sessionState.currentFarmId());
    if (!agentFarmSelected) {
      sessionState.changeFarmId(this.agentFarms[0].id());
    }

    this.state = {
      movingFarm: null,
      movingDate: null,
      cows: [
        {cowUid: '', newCowNo: '', newCowGroup: null, forFattening: false}
      ],
      cowLabelOptions: [],
      cowLabel: null,
      notes: '',
      showInput: false,
      canExecute: false,
      showProgress: false,
      showDialog: false,
    };
  }

  componentDidMount() {
    depositFarmRelationAPI.index().then((res) => {
      const depositorFarmIds = {};
      const agentFarmIds = {};
      res.data.forEach((r) => {
        depositorFarmIds[r.agentFarmId] = r.depositorFarmId;

        const farmIds = agentFarmIds[r.depositorFarmId] || [];
        farmIds.push(r.agentFarmId);
        agentFarmIds[r.depositorFarmId] = farmIds;
      });

      this.depositorFarmIds = depositorFarmIds;
      this.agentFarmIds = agentFarmIds;

      this.setMovingFarmOptions();
    });
  }

  pickupAgentFarms(farms) {
    return farms.filter((farm) => farm.isAgent());
  }

  setMovingFarmOptions() {
    const depositorFarmId = this.depositorFarmIds[sessionState.currentFarmId()];
    const agentFarmIds = this.agentFarmIds[depositorFarmId];

    const movingFarmOptions = this.agentFarms
      .filter((farm) => agentFarmIds.includes(farm.id()) && farm.id() !== sessionState.currentFarmId())
      .map((farm) => {
        return {value: farm.id(), label: farm.farmName()};
      });

    this.setState({
      movingFarmOptions: movingFarmOptions,
      movingFarm: movingFarmOptions[0]
    });

    const movingFarmId = movingFarmOptions[0].value;
    this.setCowLabelOptions(movingFarmId);
    this.setMovingCowGroupOptions(movingFarmId);
  }

  setMovingCowGroupOptions(farmId) {
    this.setState({showProgress: true});

    cowGroupAPI.index(farmId).then((res) => {
      const cowGroupOptions = res.data.map((r) => {
        return {value: r.cowGroupId, label: r.cowGroupName, pens: r.pens || ''};
      });

      const cows = this.state.cows;
      cows.forEach((cow) => cow.newCowGroup = null);

      this.setState({
        movingCowGroupOptions: cowGroupOptions,
        cows: cows
      }, this.validate());

      this.setState({showProgress: false});
    });
  }

  onChangeMovingFarm(option) {
    this.setState({movingFarm: option});

    const movingFarmId = option.value;
    this.setCowLabelOptions(movingFarmId);
    this.setMovingCowGroupOptions(movingFarmId);
  }

  setCowLabelOptions(farmId) {
    masterCowLabelAPI.available(farmId).then((res) => {
      const cowLabelOptions = res.data.map((record) => {
        return {
          value: record.id,
          label: record.name
        };
      });
      const cowLabel = cowLabelOptions.find((e) => e.label === '牧場異動');

      this.setState({
        cowLabelOptions: cowLabelOptions,
        cowLabel: cowLabel,
      });
    });
  }

  onChangeInput(key, e) {
    this.setState({[key]: e.target.value}, () => this.validate());
  }

  onChangeDate(key, value) {
    this.setState({[key]: value}, () => this.validate());
  }

  onChangeSelect(key, option) {
    this.setState({[key]: option});
  }

  onChangeCowInput(index, key, e) {
    const cows = this.state.cows;
    cows[index][key] = e.target.value;
    this.setState({cows: cows}, () => this.validate());
  }

  onChangeCowSelect(index, key, option) {
    const cows = this.state.cows;
    cows[index][key] = option;
    this.setState({cows: cows}, () => this.validate());
  }

  onChangeCowCheckbox(index, key, e) {
    const cows = this.state.cows;
    cows[index][key] = e.target.checked;
    this.setState({cows: cows}, () => this.validate());
  }

  onChangeCowGroup(index, option) {
    const cows = this.state.cows;
    cows[index]['newCowGroup'] = option;
    this.setState({cows: cows}, () => this.validate());

    this.setMovingPenOptions(index, option.pens);
  }

  setMovingPenOptions(index, pens) {
    const penOptions = pens.split(',').map((pen) => {
      return {value: pen, label: pen};
    });

    const cows = this.state.cows;
    const cow = cows[index];

    cow.penOptions = penOptions;
    cow.newPen = null;

    this.setState({
      cows: cows
    });
  }

  addCow() {
    const cow = {cowUid: '', newCowNo: '', newCowGroup: null, forFattening: true};
    const cows = this.state.cows;
    cows.push(cow);
    this.setState({cows: cows}, () => this.validate());
  }

  removeCow(index) {
    const cows = this.state.cows;
    cows.splice(index, 1);
    this.setState({cows: cows}, () => this.validate());
  }

  validate() {
    if (!this.state.movingDate) {
      this.setState({canExecute: false});
      return;
    }

    const canExecute = FarmMovingIndex.validateCows(this.state.cows);
    this.setState({canExecute: canExecute});
  }

  static validateCows(cows) {
    return cows.every((cow) => {
      if (!StringUtil.isDigit(cow.cowUid)) return false;
      if (cow.cowUid.length !== 10) return false;

      if (!cow.newCowGroup) return false;

      return true;
    });
  }

  save() {
    const params = this.createParams();
    this.setState({showProgress: true});

    farmMovingAPI.create(params).then((res) => {
      this.notification = '異動が完了しました';
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({
          showDialog: false,
          canExecute: false,
          errorMessage: ''
        });
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({
        errorMessage: errorMessage,
        canExecute: false,
      });
      console.error(errorMessage);
    });
  }

  createParams() {
    const cows = this.state.cows.map((cow) => {
      const newPen = cow.newPen || {value: ''};
      return {
        cowUid: cow.cowUid,
        newCowNo: cow.newCowNo,
        newCowGroupId: cow.newCowGroup.value,
        newPen: newPen.value,
        forFattening: cow.forFattening,
      };
    });

    let movingMasterCowLabelId = 0;
    if (this.state.cowLabel) {
      movingMasterCowLabelId = this.state.cowLabel.value;
    }

    return {
      accountId: sessionState.accountId(),
      farmId: sessionState.currentFarmId(),
      notes: this.state.notes,
      movingFarmId: this.state.movingFarm.value,
      movingDate: DateUtil.toMSec(this.state.movingDate),
      movingMasterCowLabelId: movingMasterCowLabelId,
      cows: cows,
    };
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>牧場異動(暫定機能)</h2>

        <ul className={classes.flexContainer} style={{marginBottom: 30}}>
          <li style={{marginRight: 50}}>
            <FarmSelect
              changeAction={() => this.setMovingFarmOptions()}
              filterFunction={(farms) => this.pickupAgentFarms(farms)}
            />
          </li>

          <li>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.save()}
              disabled={!this.state.canExecute}
            >
              実行
            </Button>
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <div style={{marginLeft: 20}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        <div style={{marginLeft: 130}}>
          <div className={classes.flexContainer} style={{marginBottom: 20}}>
            <div style={{width: 500, marginRight: 30}}>
              <InputLabel htmlFor="action-type" style={{fontSize: 14, marginBottom: 5}}>異動先牧場</InputLabel>

              <Select
                options={this.state.movingFarmOptions}
                value={this.state.movingFarm}
                onChange={(option) => this.onChangeMovingFarm(option)}
                inputProps={{id: 'action-type'}}
              />
            </div>

            <div style={{width: 150, marginRight: 30}}>
              <DatePickerWrapper
                label="異動日"
                date={this.state.movingDate}
                syncState={(value) => this.onChangeDate('movingDate', value)}
              />
            </div>
          </div>

          <div className={classes.flexContainer} style={{marginBottom: 20}}>
            <div style={{width: 300, marginRight: 30}}>
              <InputLabel htmlFor="action-type" style={{fontSize: 14, marginBottom: 5}}>識別用の個体ラベル</InputLabel>

              <Select
                options={this.state.cowLabelOptions}
                value={this.state.cowLabel}
                onChange={(option) => this.onChangeSelect('cowLabel', option)}
                inputProps={{id: 'action-type'}}
              />
            </div>

            <div>
              <div style={{color: '#929292', fontSize: 14}}>備考</div>

              <TextField
                type="text"value={this.state.notes}
                variant="outlined" multiline={true} rows={2}
                margin="dense" style={{width: 500, marginTop: 0}}
                onChange={(e) => this.onChangeInput('notes', e)}
              />
            </div>
          </div>

          {this.state.cows.map((cow, index) => {
            return (
              <div key={index}>
                <div className={classes.enclosedArea} style={{width: 550, marginBottom: 20, padding: 15}}>
                  <div className={classes.flexContainer} style={{marginBottom: 20}}>
                    <div style={{marginRight: 30}}>
                      <TextField
                        type="text" label="個体識別番号"
                        value={cow.cowUid} onChange={(e) => this.onChangeCowInput(index, 'cowUid', e)}
                        margin="none" required
                        className={classes.textField}
                      />
                    </div>

                    <div style={{marginRight: 30}}>
                      <TextField
                        type="text" label="異動先での牛番号"
                        value={cow.newCowNo} onChange={(e) => this.onChangeCowInput(index, 'newCowNo', e)}
                        margin="none"
                        className={classes.textField}
                      />
                    </div>

                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cow.forFattening}
                            onChange={(e) => this.onChangeCowCheckbox(index, 'forFattening', e)}
                            color="primary"
                          />
                        }
                        label="肥育牛とする"
                      />
                    </div>
                  </div>

                  <div className={classes.flexContainer} style={{marginBottom: 20}}>
                    <div style={{width: 350, marginRight: 30}}>
                      <InputLabel htmlFor="cow-group" style={{fontSize: 14, marginBottom: 5}}>異動先の牛群*</InputLabel>
                      <Select
                        options={this.state.movingCowGroupOptions}
                        value={cow.newCowGroup}
                        onChange={(option) => this.onChangeCowGroup(index, option)}
                        input={<Input id="cow-group" />}
                        style={{minWidth: 150}}
                      />
                    </div>

                    <div>
                      <InputLabel htmlFor="pen" style={{fontSize: 14, marginBottom: 5}}>異動先の牛房</InputLabel>
                      <Select
                        options={cow.penOptions}
                        value={cow.newPen}
                        onChange={(option) => this.onChangeCowSelect(index, 'newPen', option)}
                        input={<Input id="pen" />}
                        style={{minWidth: 50}}
                      />
                    </div>
                  </div>

                  {index >= 1 ?
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        size="small"
                        onClick={() => this.removeCow(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    : null
                  }
                </div>
              </div>
            );
          })}

          <div>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => this.addCow()}
            >
              牛の追加
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

FarmMovingIndex.propTypes = {
  classes: PropTypes.object.isRequired,
};

const forExport = withStyles(styles)(FarmMovingIndex);
export {forExport as FarmMovingIndex};
