import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

export const ReturnButton = (props) => {
  return (
    <div style={{marginBottom: '20px'}}>
      <Button variant="outlined" size="small" color="primary" onClick={() => props.returnAction()}>
        一覧に戻る
      </Button>
    </div>
  );
};

ReturnButton.propTypes = {
  returnAction: PropTypes.func.isRequired,
};
