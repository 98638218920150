export class CowEventImport {

  static get importColumnDefinitions() {
    return {
      'fresh_check': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: 'フレッシュチェック'},
        {label: 'フレッシュチェック結果', value: '良好'},
        {label: 'BCS', value: '3.5'},
        {label: '卵巣診断結果(左)', value: '良好'},
        {label: '卵巣診断結果(右)', value: '注意'},
        {label: '子宮診断結果', value: '悪い'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'heat': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10 12:00'},
        {label: 'イベント名称', value: '発情'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'timed_ai': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10 12:00'},
        {label: 'イベント名称', value: 'ホルモンプログラム'},
        {label: 'ホルモンプログラム名', value: 'Obsync'},
        {label: '処置内容', value: 'PG'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'bred': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10 12:00'},
        {label: 'イベント名称', value: '種付'},
        {label: '種付方法', value: '人工授精'},
        {label: '精液番号', value: 'PK-482'},
        {label: '作業者名', value: 'サンプル太郎'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'pregnant_diagnosis': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '妊娠鑑定'},
        {label: '妊娠鑑定結果', value: '受胎'},
        {label: '対象種付日', value: '2017/8/10'},
        {label: '作業者名', value: 'サンプル太郎'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'dried': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '乾乳'},
        {label: '乾乳前後期', value: '前期'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'aborted': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '流産'},
        {label: '牛個体の状態(戻り先)', value: '未受胎(－)'},
      ],
      'calved': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '分娩'},
        {label: '出生頭数', value: '2'},
        {label: '分娩難易度', value: '1'},
        {label: '子牛出産結果(1頭目)', value: '出産'},
        {label: '子牛出産結果(2頭目)', value: '死産'},
        {label: '子牛出産結果(3頭目)', value: ''},
        {label: '子牛出産結果(4頭目)', value: ''},
        {label: '出産子牛性別(1頭目)', value: 'メス'},
        {label: '出産子牛性別(2頭目)', value: 'オス'},
        {label: '出産子牛性別(3頭目)', value: ''},
        {label: '出産子牛性別(4頭目)', value: ''},
        {label: '出生時体重(1頭目)', value: '35'},
        {label: '出生時体重(2頭目)', value: '37'},
        {label: '出生時体重(3頭目)', value: ''},
        {label: '出生時体重(4頭目)', value: ''},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'embryo_recovery': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2019/9/3'},
        {label: 'イベント名称', value: '採卵'},
        {label: 'ランクAの個数', value: '2'},
        {label: 'ランクBの個数', value: '3'},
        {label: 'ランクCの個数', value: '1'},
        {label: 'ランクDの個数', value: '0'},
        {label: '未授精の個数', value: '0'},
        {label: '精液番号', value: 'PK-482'},
        {label: '対象種付日', value: '2019/8/13'},
        {label: 'ホルモンプログラム名', value: 'Obsync'},
      ],
      'do_not_breed': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2019/9/3'},
        {label: 'イベント名称', value: '繁殖除外'},
        {label: '除外理由', value: '低乳量'},
      ],
      'mastitis': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '乳房炎'},
        {label: '疾病発生日時', value: '2017/9/10 12:00'},
        {label: '体温', value: '37.5'},
        {label: '投薬品名', value: 'オキシトシン、メトクロ'},
        {label: '投与方法', value: '筋肉注射、経口投与'},
        {label: '投与量', value: '5、3'},
        {label: '乳房炎菌(左前)', value: '大腸菌'},
        {label: '乳房炎菌(右前)', value: '黄色ブドウ球菌'},
        {label: '乳房炎菌(左後)', value: '不明'},
        {label: '乳房炎菌(右後)', value: '大腸菌'},
        {label: '乳房炎診断結果(左前)', value: '血乳'},
        {label: '乳房炎診断結果(右前)', value: 'ぶつ少ない'},
        {label: '乳房炎診断結果(左後)', value: '水様性'},
        {label: '乳房炎診断結果(右後)', value: '様子見'},
        {label: '盲乳処置(左前)', value: 'TRUE'},
        {label: '盲乳処置(右前)', value: ''},
        {label: '盲乳処置(左後)', value: 'TRUE'},
        {label: '盲乳処置(右後)', value: ''},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'reproductive_disorders': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '繁殖障害'},
        {label: '疾病発生日時', value: '2017/9/10 12:00'},
        {label: '体温', value: '37.5'},
        {label: '投薬品名', value: 'オキシトシン、メトクロ'},
        {label: '投与方法', value: '筋肉注射、経口投与'},
        {label: '投与量', value: '5、3'},
        {label: '卵巣静止・萎縮軽重度', value: '軽'},
        {label: '卵胞嚢腫軽重度', value: '中'},
        {label: '黄体遺残軽重度', value: '重'},
        {label: '子宮炎軽重度', value: '重'},
        {label: '子宮蓄膿症軽重度', value: '重'},
        {label: '卵巣診断結果(左)', value: '悪い'},
        {label: '卵巣診断結果(右)', value: '悪い'},
        {label: '子宮診断結果', value: '悪い'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'perinatal_and_metabolic_diseases': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '周産病・代謝病'},
        {label: '疾病発生日時', value: '2017/9/10 12:00'},
        {label: '体温', value: '37.5'},
        {label: '投薬品名', value: 'オキシトシン、メトクロ'},
        {label: '投与方法', value: '筋肉注射、経口投与'},
        {label: '投与量', value: '5、3'},
        {label: '子宮炎軽重度', value: '軽'},
        {label: 'ケトーシス軽重度', value: '中'},
        {label: '脂肪肝軽重度', value: '重'},
        {label: '第四胃変位軽重度', value: '重'},
        {label: '低カルシウム血症(乳熱)軽重度', value: '重'},
        {label: 'ルーメンアシドーシス軽重度', value: '重'},
        {label: 'ダウナー症候群軽重度', value: '重'},
        {label: '胎盤停滞軽重度', value: '重'},
        {label: '尿石軽重度', value: '重'},
        {label: 'ビタミンA欠乏症軽重度', value: '重'},
        {label: '鼓張症軽重度', value: '重'},
        {label: '食滞軽重度', value: '重'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'injured': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '外傷'},
        {label: '疾病発生日時', value: '2017/9/10 12:00'},
        {label: '体温', value: '37.5'},
        {label: '投薬品名', value: 'オキシトシン、メトクロ'},
        {label: '投与方法', value: '注射薬、経口薬'},
        {label: '投与量', value: '筋肉注射、経口投与'},
        {label: '股関節脱臼軽重度', value: '軽'},
        {label: '乳頭損傷軽重度', value: '中'},
        {label: 'その他軽重度', value: '重'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'claw_diseases': [
        {label: '個体識別番号', value: '1234567890'},
        {label: 'イベント名称', value: '蹄病'},
        {label: '発生日時', value: '1/1/2018'},
        {label: '疾病発生日時', value: '2017/9/10 12:00'},
        {label: '体温', value: '37.5'},
        {label: '跛行スコア', value: '3'},
        {label: '蹄病-肢1', value: '左前'},
        {label: '蹄病-肢2', value: '右後'},
        {label: '蹄病-肢3', value: ''},
        {label: '蹄病-肢4', value: ''},
        {label: '蹄病-部位1', value: '蹄'},
        {label: '蹄病-部位2', value: '蹄'},
        {label: '蹄病-部位3', value: ''},
        {label: '蹄病-部位4', value: ''},
        {label: '蹄病-蹄箇所1', value: '内'},
        {label: '蹄病-蹄箇所2', value: '外'},
        {label: '蹄病-蹄箇所3', value: ''},
        {label: '蹄病-蹄箇所4', value: ''},
        {label: '蹄病-状態1', value: '軽'},
        {label: '蹄病-状態2', value: '軽'},
        {label: '蹄病-状態3', value: ''},
        {label: '蹄病-状態4', value: ''},
        {label: '蹄病名1', value: '趾間フレグモーネ'},
        {label: '蹄病名2', value: ''},
        {label: '蹄病名3', value: ''},
        {label: '蹄病名4', value: ''},
        {label: '診断結果詳細', value: ''},
        {label: '投薬品名', value: ''},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'infection_diseases': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '感染症'},
        {label: '疾病発生日時', value: '2017/9/10 12:00'},
        {label: '体温', value: '37.5'},
        {label: '投薬品名', value: 'オキシトシン、メトクロ'},
        {label: '投与方法', value: '筋肉注射、経口投与'},
        {label: '投与量', value: '5、3'},
        {label: 'その他軽重度', value: '軽'},
        {label: '肺炎軽重度', value: '中'},
        {label: '下痢軽重度', value: '重'},
        {label: '皮膚病軽重度', value: '重'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'others': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: 'その他'},
        {label: '疾病発生日時', value: '2017/9/10 12:00'},
        {label: 'その他症状', value: '病気1'},
        {label: '体温', value: '37.5'},
        {label: '投薬品名', value: 'オキシトシン、メトクロ'},
        {label: '投与方法', value: '筋肉注射、経口投与'},
        {label: '投与量', value: '5、3'},
        {label: 'その他軽重度', value: '重'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'moved': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '牛群移動'},
        {label: '移動先牛群', value: '搾乳群1'},
        {label: '移動先牛房', value: '東1'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'vaccination': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: 'ワクチン'},
        {label: '投薬品名', value: 'ボビリス'},
        {label: 'ワクチンタイミング', value: '導入時'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'claw_trimmed': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '削蹄'},
        {label: '作業者名', value: '削蹄次郎'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'dehorned': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '除角'},
        {label: '除角方法', value: '焼きごて法'},
        {label: '作業者名', value: '削蹄次郎'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'castrated': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '去勢'},
        {label: '去勢方法', value: '無血去勢法'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'culled': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '出荷'},
        {label: '販売先', value: ''},
        {label: '販売価格', value: '1000000'},
        {label: '除籍理由', value: '乳用売却'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'died': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: 'へい死'},
        {label: '除籍理由', value: '死亡'},
        {label: 'へい死理由', value: '外傷・事故'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'carcass_characteristic': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '枝肉成績'},
        {label: '歩留肉質等級', value: 'A5'},
        {label: '歩留等級', value: 'A'},
        {label: '肉質等級', value: '5'},
        {label: 'と畜番号', value: '12'},
        {label: 'と殺前重量', value: '450.7'},
        {label: '枝肉重量(左)', value: '200.5'},
        {label: '枝肉重量(右)', value: '200.5'},
        {label: '枝肉総重量', value: '420.6'},
        {label: '胸最長筋面積', value: '85'},
        {label: 'ばらの厚さ', value: '8.5'},
        {label: '皮下脂肪の厚さ', value: '1.5'},
        {label: '歩留基準値', value: '75.5'},
        {label: 'BMSNo', value: '12'},
        {label: '脂肪交雑等級', value: '5'},
        {label: 'BCSNo', value: '7'},
        {label: '光沢', value: '5'},
        {label: '肉色及び光沢の等級', value: '5'},
        {label: '締まり', value: '5'},
        {label: 'きめ', value: '5'},
        {label: '締まり及びきめの等級', value: '5'},
        {label: 'BFSNo', value: '7'},
        {label: '脂肪の色沢と質', value: '5'},
        {label: '色沢と質の等級', value: '5'},
        {label: '瑕疵', value: 'カ'},
        {label: '瑕疵2', value: 'ア'},
        {label: '瑕疵部位', value: 'かた'},
        {label: '瑕疵部位2', value: 'すね'},
        {label: 'その他瑕疵歩留補正', value: '0'},
        {label: '枝肉単価(kg)', value: '500'},
        {label: '枝肉販売金額(万)', value: '500'},
        {label: '内臓破棄', value: '肝臓:頭'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'weaned': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '離乳'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'fattened': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10'},
        {label: 'イベント名称', value: '肥育'},
        {label: 'コメント', value: '採食が低下している'},
      ],
      'work_note': [
        {label: '個体識別番号', value: '1234567890'},
        {label: '発生日時', value: '2017/9/10 12:00'},
        {label: 'イベント名称', value: '作業メモ'},
        {label: '作業メモタイトル', value: '個体の活動量確認'},
        {label: 'コメント', value: '採食が低下している'},
      ],
    };
  }

  static importColumns(eventType) {
    return CowEventImport.importColumnDefinitions[eventType] || [];
  }
}
