import {apiBase} from 'api/base.js';

class AuthorizedFarmAPI {
  index(farmId) {
    return apiBase.get('/admin/authorized_farm');
  }

  show(accountId) {
    return apiBase.get(`/admin/authorized_farm/${accountId}`);
  }

  create(params) {
    return apiBase.post('/admin/authorized_farm', params);
  }
}

export const authorizedFarmAPI = new AuthorizedFarmAPI();
