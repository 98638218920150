import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {cowEventAPI} from 'api/cow-event.js';
import {DateUtil} from 'utils/date-util.js';
import {CowEventColumn} from 'models/cow-event-column.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {SimpleTable} from 'components/tables/SimpleTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  selectField: {
    minWidth: 150,
  },
};

class CowEventIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'id', label: 'ID', style: {width: 20, maxWidth: 20}},
      {id: 'cowId', label: '個体ID', style: {width: 20, maxWidth: 20}},
      {id: 'cowUid', label: '個体識別番号', style: {width: 60, maxWidth: 60}},
      {id: 'occurredAtDisplay', label: '発生日時', style: {width: 40, maxWidth: 40}},
      {id: 'eventName', label: 'イベント名'},
      {id: 'updatedAtDisaply', label: '更新日時', style: {width: 40, maxWidth: 40}},
      {id: 'detail', label: '詳細'},
    ];

    this.state = {
      records: [],
      columns: columns,
      narrow24h: false,
      showProgress: false
    };

    this.inputPath = 'admin';
  }

  componentDidMount() {
    this.index();
  }

  index() {
    this.setState({showProgress: true});

    return cowEventAPI.index(sessionState.currentFarmId(), this.inputPath).then((res) => {
      const records = res.data.map((r) => {
        r.occurredAtDisplay = DateUtil.toYYYYMMDDHHmm(r.occurredAt).replace(' 00:00', '');
        r.updatedAtDisaply = DateUtil.toYYYYMMDDHHmm(r.updatedAt);
        r.detail = CowEventColumn.generateDetail(r);
        return r;
      });

      this.rawRecords = records;

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  onChangeInputPath(e) {
    this.inputPath = e.target.value;
    this.index();
  }

  onChangeNarrow24h(e) {
    const narrow24h = e.target.checked;
    this.setState({narrow24h: narrow24h});

    let records = [].concat(this.rawRecords);
    if (narrow24h) {
      const time = DateUtil.addHours(new Date(), -24).getTime();
      records = records.filter((r) => {
        return r.updatedAt >= time;
      });
    }

    this.setState(() => ({
      records: records
    }));
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>イベントデータ</h2>

        <ul className={classes.flexContainer}>
          <li style={{marginRight: 50}}>
            <FarmSelect changeAction={() => this.index()} />
          </li>

          <li style={{marginRight: 30}}>
            <InputLabel htmlFor="input-path" style={{fontSize: 12}}>入力経路</InputLabel>
            <Select
              className={classes.selectField}
              value={this.inputPath}
              onChange={(e) => this.onChangeInputPath(e)}
              inputProps={{name: 'inputPath', id: 'input-path'}}
            >
              <MenuItem value={'all'}>全て</MenuItem>
              <MenuItem value={'u-motion'}>u-motion</MenuItem>
              <MenuItem value={'file-import'}>ファイル取込</MenuItem>
              <MenuItem value={'admin'}>管理機能</MenuItem>
              <MenuItem value={'sql'}>SQL作成</MenuItem>
            </Select>
          </li>

          <li>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.narrow24h}
                  onChange={(e) => this.onChangeNarrow24h(e)}
                  color="primary"
                />
              }
              label="24時間以内に更新されたもの"
            />
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <SimpleTable
          records={this.state.records}
          columns={this.state.columns}
        />
      </div>
    );
  }
}

CowEventIndex.propTypes = {
  classes: PropTypes.object.isRequired,
};

const forExport = withStyles(styles)(CowEventIndex);
export {forExport as CowEventIndex};
