import {sessionState} from 'session/session-state.js';
import {DateUtil} from 'utils/date-util.js';

class APIBase {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL;
  }

  request(path, httpMethod, params = null, needAuthentication = true) {
    const url = `${this.baseUrl}${path}`;
    const option = {
      method: httpMethod,
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      }
    };

    if (['POST', 'PUT', 'DELETE'].some((method) => method === httpMethod)) {
      option.body = JSON.stringify(params);
    }

    if (needAuthentication) {
      return this.refreshToken().then(() => {
        option.headers['Authorization'] = 'JWT ' + sessionState.sessionToken();
        return this.httpRequest(url, option);
      }).catch((err) => {
        if (err.status === 401 && err.data.errorCode === 'NO_ACCOUNT') {
          sessionState.logout();
          window.location.href = '/login';
        }
        throw err;
      });
    } else {
      if (path === '/admin/session' && httpMethod === 'PUT') {
        const token = `Bearer ${sessionState.refreshToken()}, JWT ${sessionState.sessionToken()}`;
        option.headers['Authorization'] = token;
      }
      return this.httpRequest(url, option);
    }
  }

  refreshToken() {
    if (DateUtil.diffSeconds(new Date(), sessionState.expiredAt()) < 60) {
      return this.request('/admin/session', 'PUT', {}, false).then((res) => {
        sessionState.login(res.data);
        return res;
      });
    } else {
      return Promise.resolve({});
    }
  }

  httpRequest(url, option, retryCount = 0) {
    return fetch(url, option)
      .then((res) => {
        return res.text().then((text) => {
          const status = res.status;
          return {data: text.trim(), status: status, ok: res.ok};
        });
      })
      .then((res) => {
        const data = res.data ? JSON.parse(res.data) : {};
        const result = {data: data, status: res.status};
        if (!res.ok) {
          return Promise.reject(result);
        }
        return result;
      })
      .catch((err) => {
        if (err.data.errorCode === 'UNAUTHORIZED') {
          if (retryCount < 2) {
            return this.refreshToken().then(() => {
              option.Authorization = `JWT ${sessionState.sessionToken()}`;
              return this.httpRequest(url, option, retryCount + 1);
            });
          } else {
            sessionState.logout();
            window.location.href = '/login';
          }
        }
        throw err;
      });
  }

  get(path) {
    return this.request(path, 'GET');
  }

  post(path, params) {
    return this.request(path, 'POST', params);
  }

  put(path, params) {
    return this.request(path, 'PUT', params);
  }

  delete(path, params = null) {
    return this.request(path, 'DELETE', params);
  }
}

export const apiBase = new APIBase();

