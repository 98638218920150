export class Cow {
  static get GENDER_OPTIONS() {
    return [
      {value: 'オス', label: 'オス'},
      {value: 'メス', label: 'メス'},
      {value: 'フリーマーチン', label: 'フリーマーチン'},
      {value: '去勢', label: '去勢'}
    ];
  }

  static get BREED_OPTIONS() {
    return [
      {value: '黒毛和種', label: '黒毛和種'},
      {value: '褐毛和種', label: '褐毛和種'},
      {value: '日本短角種', label: '日本短角種'},
      {value: '無角種', label: '無角種'},
      {value: '黒毛と褐毛の交雑種', label: '黒毛と褐毛の交雑種'},
      {value: '和牛間交雑種', label: '和牛間交雑種'},
      {value: '肉専用種', label: '肉専用種'},
      {value: 'ホルスタイン種', label: 'ホルスタイン種'},
      {value: 'ジャージー種', label: 'ジャージー種'},
      {value: '乳用種', label: '乳用種'},
      {value: '交雑種', label: '交雑種'}
    ];
  }

  static get STATE_OPTIONS() {
    return [
      {value: 'フレッシュ', label: 'フレッシュ'},
      {value: '未授精', label: '未授精'},
      {value: '授精', label: '授精'},
      {value: '未受胎(－)', label: '未受胎(－)'},
      {value: '受胎(＋)', label: '受胎(＋)'},
      {value: '乾乳前期', label: '乾乳前期'},
      {value: '乾乳後期', label: '乾乳後期'},
      {value: '出荷', label: '出荷'},
      {value: 'へい死', label: 'へい死'},
      {value: 'その他', label: 'その他'},
      {value: '哺育', label: '哺育'},
      {value: '育成', label: '育成'},
      {value: '肥育', label: '肥育'},
      {value: '繁殖除外', label: '繁殖除外'}
    ];
  }
}
