import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import {notificationAPI} from 'api/notification.js';
import {DateUtil} from 'utils/date-util.js';
import {ErrorUtil} from 'utils/error-util.js';
import {ViewMode} from 'models/view-mode.js';

import {ReturnButton} from 'components/ReturnButton.js';
import {NotificationInput} from 'components/forms/notification/NotificationInput.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  readonlyText: GlobalCss.readonlyText,
  inputBox: {
    marginBottom: '20px',
  },
  textField: {
    width: 400,
  },
  textAreaField: {
    width: 400,
  },
};

class NotificationEdit extends React.Component {
  constructor(props) {
    super();

    this.state = {
      record: this.createInitialRecord(),
      loaded: false,
      showProgress: false,
      showDialog: false,
    };

    const params = props.match.params;
    this.id = params.id ? parseInt(params.id, 10) : null;
    this.viewMode = new ViewMode(params.action);

    this.title = `お知らせ - ${this.viewMode.label}`;
    this.notification = `${this.viewMode.label}が完了しました`;
    this.buttonColor = this.viewMode.isDelete() ? 'secondary' : 'primary';
  }

  createInitialRecord() {
    return {
      farmType: 'ALL',
      contractedPlan: 'ALL',
      startAt: null,
      endAt: null,
      title: '',
      content: '',
      category: '',
      priority: 0,
    };
  }

  componentDidMount() {
    if (this.viewMode.isCreate()) {
      this.setState({
        loaded: true
      });
      return;
    }

    this.show();
  }

  show() {
    this.setState({showProgress: true});

    return notificationAPI.show(this.id).then((res) => {
      const record = Object.assign({}, res.data);
      record.startAt = DateUtil.toDate(record.startAt);
      record.endAt = DateUtil.toDate(record.endAt);

      this.setState({
        record: record,
        loaded: true,
        showProgress: false
      });
    });
  }

  returnAction() {
    this.props.history.push('/notification');
  }

  syncState(key, value) {
    const input = this.state.input || {...this.state.record};
    input[key] = value;
    this.setState({input: input});
  }

  validate() {
    if (this.viewMode.isDelete()) {
      return {valid: true};
    }

    const input = this.state.input;
    const errors = [];

    if (this.viewMode.isUpdate() && !input) {
      errors.push('内容が変更されていません');
      return {valid: false, errors: errors};
    }

    if (this.viewMode.isCreate() && !input) {
      errors.push('内容を入力してください');
      return {valid: false, errors: errors};
    }

    if (!input.startAt) {
      errors.push('表示開始時刻を入力してください');
    }

    if (!input.title) {
      errors.push('タイトルを入力してください');
    }

    if (!input.content) {
      errors.push('お知らせ内容を入力してください');
    }

    if (input.startAt && input.endAt && (input.startAt > input.endAt)) {
      errors.push('表示終了時刻は表示開始時刻より後にしてください');
    }

    return {valid: errors.length === 0, errors: errors};
  }

  save() {
    const result = this.validate();
    if (!result.valid) {
      this.setState({errorMessage: result.errors.join('\n')});
      return;
    } else {
      this.setState({errorMessage: ''});
    }

    let action;
    if (this.viewMode.isCreate()) {
      action = notificationAPI.create(this.createParams());
    } else if (this.viewMode.isUpdate()) {
      action = notificationAPI.update(this.id, this.createParams());
    } else if (this.viewMode.isDelete()) {
      action = notificationAPI.delete(this.id);
    }

    this.setState({showProgress: true});

    action.then((res) => {
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({showDialog: false});
        this.returnAction();
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({errorMessage: errorMessage});
      console.error(errorMessage);
    });
  }

  createParams() {
    const input = this.state.input;
    return {
      farmType: input.farmType,
      contractedPlan: input.contractedPlan,
      startAt: DateUtil.toMSec(input.startAt),
      endAt: input.endAt ? DateUtil.toMSec(input.endAt) : null,
      title: input.title,
      content: input.content,
      category: input.category || '',
      priority: input.priority
    };
  }

  render() {
    const classes = this.props.classes;

    return (
      <Box style={{marginLeft: '20px'}}>
        <h2>{this.title}</h2>

        <List className={classes.flexContainer}>
          <ListItem>
            <ReturnButton returnAction={() => this.returnAction()} />
          </ListItem>

          <ListItem style={{marginBottom: '20px'}}>
            <Button
              variant="contained"
              color={this.buttonColor}
              onClick={() => this.save()}
              disabled={this.state.showProgress}>
              {this.viewMode.label}
            </Button>
          </ListItem>
        </List>

        <Box style={{marginLeft: '40px'}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </Box>

        <Box style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </Box>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <DetailForm
          loaded={this.state.loaded}
          record={this.state.record}
          viewMode={this.viewMode}
          syncState={this.syncState.bind(this)}
          classes={classes}
        />
      </Box>
    );
  }
}

NotificationEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(NotificationEdit));
export {forExport as NotificationEdit};

const DetailForm = (props) => {
  if (props.loaded) {
    if (props.viewMode.isDelete()) {
      return (
        <DeleteForm classes={props.classes} record={props.record} />
      );
    } else {
      return (
        <NotificationInput
          record={props.record}
          syncState={props.syncState}
        />
      );
    }
  } else {
    return null;
  }
};

DetailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};

const DeleteForm = (props) => {
  const classes = props.classes;
  const record = props.record;
  return (
    <>
      <Box className={classes.inputBox}>
        <FormControl>
          <TextField
            type="text"
            placeholder=""
            label="タイトル"
            value={record.title}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
            className={clsx(classes.textField, classes.readonlyText)}
          />
        </FormControl>
      </Box>
      <Box className={classes.inputBox}>
        <FormControl>
          <TextField
            type="text"
            placeholder=""
            label="お知らせ内容"
            value={record.content}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
            className={clsx(classes.textAreaField, classes.readonlyText)}
            multiline
          />
        </FormControl>
      </Box>
    </>
  );
};

DeleteForm.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};
