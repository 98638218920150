export class CowEvent {
  static eventTypes(farm) {
    const events = [
      {eventType: 'bred', label: '種付', legacyEventType: 'tanetsuke', farmType: 'ALL'},
      {eventType: 'pregnant_diagnosis', label: '妊娠鑑定', legacyEventType: 'ninshinkantei', farmType: 'ALL'},
      {eventType: 'calved', label: '分娩', legacyEventType: 'bunben', farmType: 'ALL'},
      {eventType: 'heat', label: '発情', legacyEventType: 'hatsujo', farmType: 'ALL'},
      {eventType: 'aborted', label: '流産', legacyEventType: 'abort', farmType: 'ALL'},
      {eventType: 'embryo_recovery', label: '採卵', legacyEventType: 'embryo_recovery', farmType: 'ALL'},
      {eventType: 'dried', label: '乾乳', legacyEventType: 'kannyu', farmType: 'MILK'},
      {eventType: 'moved', label: '牛群移動', legacyEventType: 'gyugunidou', farmType: 'ALL'},
      {eventType: 'vaccination', label: 'ワクチン', legacyEventType: 'vaccine', farmType: 'ALL'},
      {eventType: 'others', label: 'その他', legacyEventType: 'otherDifficulty', farmType: 'ALL'},
      {eventType: 'infection_diseases', label: '感染症', legacyEventType: 'infect', farmType: 'ALL'},
      {eventType: 'injured', label: '外傷', legacyEventType: 'injury', farmType: 'ALL'},
      {eventType: 'mastitis', label: '乳房炎', legacyEventType: 'mastitis', farmType: 'MILK'},
      {eventType: 'reproductive_disorders', label: '繁殖障害', legacyEventType: 'breedingDifficulty', farmType: 'ALL'},
      {eventType: 'perinatal_and_metabolic_diseases', label: '周産病・代謝病', legacyEventType: 'perinatalDifficulty', farmType: 'ALL'},
      {eventType: 'claw_diseases', label: '蹄病・跛行', legacyEventType: 'lame', farmType: 'ALL'},
      {eventType: 'claw_trimmed', label: '削蹄', legacyEventType: 'hoofTrim', farmType: 'ALL'},
      {eventType: 'dehorned', label: '除角', legacyEventType: 'dehorn', farmType: 'ALL'},
      {eventType: 'castrated', label: '去勢', legacyEventType: 'castration', farmType: 'ALL'},
      {eventType: 'culled', label: '出荷', legacyEventType: 'touta', farmType: 'ALL'},
      {eventType: 'died', label: 'へい死', legacyEventType: 'heishi', farmType: 'ALL'},
      {eventType: 'carcass_characteristic', label: '枝肉成績', legacyEventType: 'carcass', farmType: 'ALL'},
      {eventType: 'do_not_breed', label: '繁殖除外', legacyEventType: 'doNotBreed', farmType: 'ALL'},
      {eventType: 'fresh_check', label: 'フレッシュチェック', legacyEventType: 'freshCheck', farmType: 'ALL'},
      {eventType: 'timed_ai', label: 'ホルモンプログラム', legacyEventType: 'hormoneProgram', farmType: 'ALL'},
      {eventType: 'work_note', label: '作業メモ', legacyEventType: 'sagyomemo', farmType: 'ALL'},
      {eventType: 'individual_observation', label: '個体観察', legacyEventType: 'observation', farmType: 'ALL', importDisabled: true},
      {eventType: 'weaned', label: '離乳', legacyEventType: 'wean', farmType: 'ALL'},
      {eventType: 'fattened', label: '肥育', legacyEventType: 'fattening', farmType: 'BEEF'},
      {eventType: 'body_weight_measurement', label: '体重測定', legacyEventType: 'measurement', farmType: 'ALL', importDisabled: true},
    ];

    return events.filter((e) => e.farmType === 'ALL' || e.farmType === farm.farmType());
  }

  static importableEventTypes(farm) {
    return CowEvent.eventTypes(farm).filter((e) => !e.importDisabled);
  }
}
