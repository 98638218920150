export class Notification {
  static get farmTypes() {
    return new Map([
      ['ALL', '全て'],
      ['MILK', '乳用'],
      ['BEEF', '肉用']
    ]);
  }

  static get categories() {
    return new Map([
      ['MAINTENANCE', 'メンテナンス'],
      ['UPDATE', 'アップデート'],
      ['INCIDENT', '障害'],
      ['INFORMATION', 'お知らせ']
    ]);
  }
}
