import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {cowshedAPI} from 'api/cowshed.js';
import {DateUtil} from 'utils/date-util.js';
import {ErrorUtil} from 'utils/error-util.js';
import {ViewMode} from 'models/view-mode.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ReturnButton} from 'components/ReturnButton.js';
import {CowshedInput} from 'components/forms/cowshed/CowshedInput.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  readonlyText: GlobalCss.readonlyText,
};

class CowshedEdit extends React.Component {
  constructor(props) {
    super();

    const farm = sessionState.currentFarm();

    this.state = {
      record: {
        feedLogicSwitchingDate: farm.isUMLight() ? null : DateUtil.today(),
        drinkingSource: 'mimosa',
        heatIndexCalculationLogic: 'SLICE3H'
      },
      loaded: false,
      showProgress: false,
      showDialog: false
    };

    this.farmId = sessionState.currentFarmId();

    const params = props.match.params;
    this.cowshedId = params.id ? parseInt(params.id, 10) : null;
    this.viewMode = new ViewMode(params.action);

    this.title = `牛舎 - ${this.viewMode.label}`;
    this.notification = `${this.viewMode.label}が完了しました`;
    this.buttonColor = this.viewMode.isDelete() ? 'secondary' : 'primary';
    this.disableFeedLogicSwitchingDate = farm.isUMLight();
  }

  componentDidMount() {
    if (this.viewMode.isCreate()) {
      this.setState({
        loaded: true
      });
      return;
    }

    this.show();
  }

  show() {
    this.setState({showProgress: true});

    return cowshedAPI.show(this.farmId, this.cowshedId).then((res) => {
      const record = Object.assign({}, res.data);
      record.feedLogicSwitchingDate = DateUtil.toDate(record.feedLogicSwitchingDate);

      this.setState({
        record: record,
        loaded: true,
        showProgress: false
      });
    });
  }

  returnAction() {
    this.props.history.push('/cowshed');
  }

  syncState(key, value) {
    const input = this.state.input || {...this.state.record};
    input[key] = value;
    this.setState({input: input});
  }

  validate() {
    if (this.viewMode.isDelete()) {
      return {valid: true};
    }

    const input = this.state.input;
    const errors = [];

    if (!input) {
      errors.push('内容が変更されていません');
      return {valid: false, errors: errors};
    }

    if (!input.cowshedName) {
      errors.push('牛舎名が入力されていません');
    }

    return {valid: errors.length === 0, errors: errors};
  }

  save() {
    const result = this.validate();
    if (!result.valid) {
      this.setState({errorMessage: result.errors.join('\n')});
      return;
    } else {
      this.setState({errorMessage: ''});
    }

    let action;
    if (this.viewMode.isCreate()) {
      action = cowshedAPI.create(this.farmId, this.createParams());
    } else if (this.viewMode.isUpdate()) {
      action = cowshedAPI.update(this.cowshedId, this.createParams());
    } else if (this.viewMode.isDelete()) {
      action = cowshedAPI.delete(this.farmId, this.cowshedId);
    }

    this.setState({showProgress: true});

    action.then((res) => {
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({showDialog: false});
        this.returnAction();
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({errorMessage: errorMessage});
      console.error(errorMessage);
    });
  }

  createParams() {
    const input = this.state.input;
    return {
      farmId: this.farmId,
      cowshedName: input.cowshedName,
      drinkingSource: input.drinkingSource,
      feedLogicSwitchingDate: DateUtil.toMSec(input.feedLogicSwitchingDate),
      heatIndexCalculationLogic: input.heatIndexCalculationLogic,
      bleId1: input.bleId1 || '',
      bleId2: input.bleId2 || '',
      bleId3: input.bleId3 || '',
      bleId4: input.bleId4 || '',
      bleId5: input.bleId5 || '',
    };
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>{this.title}</h2>

        <ul className={classes.flexContainer}>
          <li>
            <ReturnButton returnAction={() => this.returnAction()} />
          </li>

          <li style={{marginLeft: '500px'}}>
            <Button
              variant="contained"
              color={this.buttonColor}
              onClick={() => this.save()}
              disabled={this.state.showProgress}>
              {this.viewMode.label}
            </Button>
          </li>
        </ul>

        <div style={{marginLeft: '40px'}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <FarmSelect changeAction={() => {}} isDisabled={true} />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <DetailForm
          loaded={this.state.loaded}
          record={this.state.record}
          viewMode={this.viewMode}
          syncState={this.syncState.bind(this)}
          classes={classes}
          disableFeedLogicSwitchingDate={this.disableFeedLogicSwitchingDate}
        />
      </div>
    );
  }
}

CowshedEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(CowshedEdit));
export {forExport as CowshedEdit};

const DetailForm = (props) => {
  if (props.loaded) {
    if (props.viewMode.isDelete()) {
      return (
        <DeleteForm classes={props.classes} record={props.record} />
      );
    } else {
      return (
        <CowshedInput
          record={props.record}
          syncState={props.syncState}
          disableFeedLogicSwitchingDate={props.disableFeedLogicSwitchingDate}
        />
      );
    }
  } else {
    return null;
  }
};

DetailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};

const DeleteForm = (props) => {
  const classes = props.classes;
  const record = props.record;
  return (
    <div style={{marginLeft: '100px'}}>
      <TextField
        type="text"
        placeholder=""
        label="牛舎名"
        value={record.cowshedName}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
        margin="none"
        className={classes.readonlyText}
      />
    </div>
  );
};

DeleteForm.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};
