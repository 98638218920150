import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {GlobalCss} from 'components/global-css.js';

const styles = {
  inputArea: {
    marginBottom: '30px',
  },
  textField: {
    width: 300,
    marginBottom: '20px',
  },
  flexContainer: GlobalCss.flexContainer,
};

class CowGroupInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.record);
    this.syncState = props.syncState;
    this.groupAttributes = props.groupAttributes || [];
    this.cowsheds = props.cowsheds;
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
    this.syncState(key, e.target.value);
  }

  onChangeCheckbox(e, key) {
    this.setState({[key]: e.target.checked});
    this.syncState(key, e.target.checked);
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '100px'}}>
        <form className={classes.inputArea}>
          <div>
            <TextField
              type="text"
              required={true}
              placeholder=""
              label="牛群名"
              value={this.state.cowGroupName}
              onChange={(e) => this.onChangeInput(e, 'cowGroupName')}
              margin="none"
              className={classes.textField}
            />
          </div>

          <div className={classes.textField}>
            <InputLabel htmlFor="cowshed" style={{fontSize: '12px'}}>牛舎</InputLabel>
            <Select
              value={this.state.cowshedId}
              onChange={(e) => this.onChangeInput(e, 'cowshedId')}
              input={<Input id="cowshed" />}
              style={{minWidth: '150px'}}
            >
              {this.cowsheds.map((c) => (
                <MenuItem key={c.cowshedId} value={c.cowshedId}>{c.cowshedName}</MenuItem>
              ))}
            </Select>
          </div>

          <div className={classes.textField}>
            <InputLabel htmlFor="groupAttribute" style={{fontSize: '12px'}}>牛群属性</InputLabel>
            <Select
              value={this.state.groupAttribute}
              onChange={(e) => this.onChangeInput(e, 'groupAttribute')}
              disabled={!this.state.groupAttributeEditable}
              input={<Input id="groupAttribute" />}
              style={{minWidth: '150px'}}
            >
              {this.groupAttributes.map((a) => (
                <MenuItem key={a.key} value={a.key}>{a.label}</MenuItem>
              ))}
            </Select>
          </div>

          <div style={{marginBottom: 20}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.menuDisplayed}
                  onChange={(e) => this.onChangeCheckbox(e, 'menuDisplayed')}
                  color="primary"
                />
              }
              label="ナビゲーションに表示する"
            />
          </div>

          <ul className={classes.flexContainer} style={{padding: 0}}>
            <li style={{width: 50}}>
              <InputLabel style={{marginTop: 10}}>牛房</InputLabel>
            </li>

            <li>
              <TextField
                type="text"
                value={this.state.pens}
                placeholder="1&#13;&#10;2&#13;&#10;3"
                onChange={(e) => this.onChangeInput(e, 'pens')}
                variant="outlined" multiline={true} rows={6}
                margin="dense" style={{width: 200}}
              />
            </li>
          </ul>
        </form>
      </div>
    );
  }
}

CowGroupInput.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  syncState: PropTypes.func.isRequired,
  groupAttributes: PropTypes.array,
  cowsheds: PropTypes.array,
};

const forExport = withStyles(styles)(CowGroupInput);
export {forExport as CowGroupInput};
