import React from 'react';
import {pushLogAPI} from 'api/push_log';
import LinearProgress from '@material-ui/core/LinearProgress';
import {SimpleTable} from 'components/tables/SimpleTable';
import {FarmSelect} from 'components/FarmSelect';
import {DateUtil} from 'utils/date-util.js';
import {sessionState} from 'session/session-state.js';

class PushLogIndexScreen extends React.Component {
  constructor() {
    super();
    const columns = [
      {id: 'notifiedAtDisplay', label: '通知日時'},
      {id: 'farmName', label: '牧場'},
      {id: 'typeLabel', label: '通知種別'},
      {id: 'messageBody', label: 'メッセージ', style: {maxWidth: 200}},
      {id: 'pushDeviceId', label: 'デバイスID'},
    ];
    this.state = {
      columns,
      pushLogs: [],
      showProgress: false
    };
  }

  componentDidMount() {
    sessionState.changeFarmId(undefined);
    this.index();
  }

  index() {
    this.setState({showProgress: true});

    return pushLogAPI.index(sessionState.currentFarmId()).then((res) => {
      const logs = res.data.map((r) => {
        r.notifiedAtDisplay = DateUtil.toYYYYMMDDHHmm(r.notifiedAt);
        r.typeLabel = r.message.notification.title;
        r.messageBody = r.message.notification.body;
        return r;
      });
      this.setState(() => ({
        logs: logs,
        showProgress: false
      }));
    });
  }

  render() {
    return (
      <>
        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>
        <FarmSelect changeAction={() => this.index()} filterFunction={(farms) => [
          ...farms,
          {
            id: () => undefined,
            farmName: () => 'すべて'
          }
        ]} />
        <SimpleTable
          records={this.state.logs}
          columns={this.state.columns}
          updateAction={(record) => this.updateAction(record)}
        />
      </>
    );
  }
}

export {PushLogIndexScreen};
