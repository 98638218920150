import * as React from 'react';
import {useState} from 'react';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {sessionState} from 'session/session-state.js';
import {DateUtil} from 'utils/date-util.js';
import {FarmSelect} from 'components/FarmSelect.js';
import {GlobalCss} from 'components/global-css.js';
import {DatePickerWrapper} from 'components/DatePickerWrapper.js';
import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

const ActivityImportRetryIndex = (props) => {
  const [selectedFarm, setSelectedFarm] = useState(sessionState.currentFarm());
  const [linkStartDate, setLinkStartDate] = useState(null);
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onDateChange = (value) => {
    setLinkStartDate(DateUtil.startOfDay(value));
  };

  const onNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const createParams = () => {
    return {
      pathname: '/activity_import_retry/confirm',
      state: {
        farm: selectedFarm,
        linkStartDate: DateUtil.toMSec(linkStartDate),
        notes: notes
      }
    };
  };

  const createAction = () => {
    const result = validate();
    if (!result.valid) {
      setErrorMessage(result.errors.join('\n'));
      return;
    } else {
      setErrorMessage('');
    }
    props.history.push(createParams());
  };

  const validate = () => {
    const errors = [];

    if (!linkStartDate) {
      errors.push('再取込開始日が指定されていません。');
    }

    if (linkStartDate) {
      if (DateUtil.isFutureDay(linkStartDate)) {
        errors.push('再取込開始日は未来の日付を指定できません。');
      }

      const sixMonthsAgo = DateUtil.addMonths(DateUtil.today(), -6);
      if (DateUtil.isBeforeDay(linkStartDate, sixMonthsAgo)) {
        errors.push('再取込開始日は6ヶ月以上過去の日付を指定できません。');
      }
    }

    if (notes && notes.length > 180) {
      errors.push('備考は180文字以内に設定してください。');
    }

    return {valid: errors.length === 0, errors: errors};
  };

  return (
    <div style={{marginLeft: '20px'}}>
      <h2>活動量再取込</h2>

      <div style={{marginLeft: '40px'}}>
        <ErrorMessage errorMessage={errorMessage} />
      </div>

      <ul className={props.classes.flexContainer}>
        <li>
          <FarmSelect changeAction={() => {
            setSelectedFarm(sessionState.currentFarm());
          }
          }/>
        </li>

        <li style={{marginLeft: '100px'}}>
          <Button variant="contained" color="primary" onClick={() => createAction()}>
            実行
          </Button>
        </li>
      </ul>

      <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
        <li style={{width: 150, marginRight: 10}}>
          <DatePickerWrapper
            label="再取込開始日"
            date={linkStartDate}
            margin="none"
            syncState={(value) => onDateChange(value)}
          />
        </li>
      </ul>

      <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
        <li>
          <TextField
            type="text"
            label="備考"
            value={notes}
            onChange={(e) => onNotesChange(e)}
            variant="outlined"
            margin="dense"
            fullWidth={true}
            className={props.classes.textAreaField}
            multiline
            rows={5}
            style={{width: 500}}
          />
        </li>
      </ul>
    </div>
  );
};

ActivityImportRetryIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(ActivityImportRetryIndex));
export {forExport as ActivityImportRetryIndex};
