import parse from 'csv-parse/lib/sync';

export class StringUtil {

  /**
   * 検査対象の文字列が指定したキーワード文字列を含むかを判定します
   *
   * @param {String} value 検査対象の文字列
   * @param {String} word キーワード文字列
   * @return {boolean} true: 指定したキーワード文字列を含む
   */
  static include(value, word) {
    if (!value || !word) return false;

    const result = value.indexOf(word);
    return result >= 0;
  }

  /**
   * 文字列を改行コードで分割し配列で返します
   *
   * @param {string} str 対象文字列
   * @return {Array.<string>} 配列
   */
  static splitByNewline(str) {
    return str ? String(str).split(/\r\n?|\n/) : [];
  }

  /**
   * 文字列が数字のみで構成されているかを判定する
   * @param {string} str 文字列
   */
  static isDigit(str) {
    if (!str) return false;
    return /^[0-9]+$/.test(str);
  }

  /**
  * 文字列が小数点を含む数字のみで構成されているかを判定する
  * @param {string} str 文字列
  */
  static isDecimal(str) {
    if (!str) return false;
    return /(^\d+$|^\d+\.\d+$)/.test(str);
  }

  /**
   * 渡された値が数値かどうかを判定する
   */
  static isNumeric(value) {
    return value === undefined || value === null || !isNaN(Number(value));
  }

  /**
   * 検査対象の文字列がメールアドレスとして有効かを判定します。
   *
   * @param {String} value 検査対象の文字列
   * @return {boolean} true: 有効
   */
  static isEmail(value) {
    if (!value) return false;
    return /^([a-zA-Z_0-9=!#%&'`{}~+\-*/?^$|]+)*^([.-]?[a-zA-Z_0-9=!#%&'`{}~+\-*/?^$|]+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value);
  }

  /**
   * 渡された数字を文字列に置き換える。
   * 渡された数字が0かnullの場合は指定された文字列に置き換えます。
   *
   * @param {number} or {string} n 数字または数字文字列
   * @param {string} replacement 0またはnullの場合に置き換える文字列
   * @return {string} 変換後の文字列
   *
   * ex. 置き換え文字列が「-」のケース
   * 1 => '1'
   * 2 => '2'
   * 0 => '-'
   * null => '-'
   * '1.5' => '1.5'
   * '0.0' => '-'
  */
  static replaceZeroOrNull(n, replacement = '') {
    const num = (typeof n === 'string') ? Number(n) : n;
    if (num) {
      return String(num);
    } else {
      return replacement;
    }
  }

  static camelToKebabCase(str) {
    if (!str) return '';
    function replacer(match, offset, string) {
      return (offset > 0 ? '-' : '') + match.toLowerCase();
    }
    return str.replace(/[A-Z]/g, replacer);
  }

  /**
   * 全角英数字を半角に変換する
   * @param {string} str
   * @return {string}
   */
  static toHalfWidthCharacters(str) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }

  /**
   * 文字列からスペースを省く
   * @param {string} str
   * @return {string}
   */
  static removeSpaces(str) {
    return str.replace(/\s/g, '');
  }

  /**
   * Excelからクリップボードにコピーされた入力値を表形式のデータに変換します。
   * @param {string} text クリップボードにコピーされた入力値(タブと改行コードを含む)
   * @return {Object}
   *
   * ex.
   * text: '牛群名\t牛舎名\t牛群属性\tナビゲーション表示\n牛群1\t牛舎A\t繁殖牛群\ttrue\n牛群2\t牛舎A\t育成牛群\tfalse'
   *
   * return {
   *   valid: true,
   *   rows: [
   *     '牛群名\t牛舎名\t牛群属性\tナビゲーション表示',
   *     '牛群1\t牛舎A\t繁殖牛群\ttrue',
   *     '牛群2\t牛舎A\t育成牛群\tfalse'
   *   ],
   *   headers: ['牛群名', '牛舎名', '牛群属性', 'ナビゲーション表示'],
   *   values: [
   *     ['牛群1', '牛舎A', '繁殖牛群', 'true'],
   *     ['牛群2', '牛舎A', '育成牛群', 'false']
   *   ]
   * }
   */
  static parseExcelMatrix(text) {
    const trimmed = text.replace(/\r/g, '');
    if (!trimmed) return {valid: false};

    const rows = StringUtil.spritExcelMatrix(trimmed);
    if (rows.length <= 1) return {valid: false};

    const headers = rows[0].split('\t');
    const values = rows.slice(1).map((row) => row.split('\t'));

    return {
      valid: true,
      rows: rows,
      headers: headers,
      values: values,
    };
  }

  /**
   * Excelからクリップボードにコピーされた文字列を改行コードで行単位に分割する。
   * @param {string} text
   * @return {Array.<string>}
   */
  static spritExcelMatrix(text) {
    if (!text) return null;

    const rows = parse(text, {
      trim: true,
      delimiter: '\t',
      skip_empty_lines: true,
      skip_lines_with_empty_values: true
    });

    return rows.map((row) => row.join('\t'));
  }
}
