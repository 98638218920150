import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {sessionState} from 'session/session-state.js';
import {cowEventImportAPI} from 'api/cow-event-import.js';
import {CowEvent} from 'models/cow-event.js';
import {CowEventImport} from 'models/import/cow-event-import.js';
import {ErrorUtil} from 'utils/error-util.js';
import {StringUtil} from 'utils/string-util.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {SimpleTable} from 'components/tables/SimpleTable.js';
import {GlobalCss} from 'components/global-css.js';
import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  selectField: {
    minWidth: 100,
  },
  longTextField: {
    width: 500,
  },
  enclosedArea: GlobalCss.enclosedArea,
};

class CowEventImportIndex extends React.Component {
  constructor() {
    super();

    const eventTypes = CowEvent.importableEventTypes(sessionState.currentFarm());

    this.state = {
      records: [],
      columns: [],
      actionType: 'insert',
      notes: '',
      eventTypes: eventTypes,
      eventType: eventTypes[0].eventType,
      showInput: false,
      canExecute: false,
      showProgress: false,
      showDialog: false
    };
  }

  setEventTypes() {
    const eventTypes = CowEvent.importableEventTypes(sessionState.currentFarm());
    this.setState({
      eventTypes: eventTypes,
      eventType: eventTypes[0].eventType
    });
  }

  static parse(text) {
    const rows = StringUtil.spritExcelMatrix(text);

    if (!rows) return {valid: false};
    if (rows.length <= 1) return {valid: false};

    const headers = rows[0].split('\t');
    const values = rows.slice(1).map((row) => row.split('\t'));

    return {
      valid: true,
      rows: rows,
      headers: headers,
      values: values,
    };
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
  }

  onChangeActionType(e) {
    this.setState({actionType: e.target.value});
  }

  pasteAction() {
    navigator.clipboard.readText().then((text) => {
      const trimmed = text.replace(/\r/g, '');
      const result = CowEventImportIndex.parse(trimmed);
      if (result.valid) {
        this.rows = result.rows;
        this.drawInputList(result.headers, result.values);
      } else {
        this.setState({
          showInput: false,
          canExecute: false
        });
      }
    });
  }

  copyInputSample() {
    const columns = CowEventImport.importColumns(this.state.eventType);
    const headers = columns.map((c) => c.label).join('\t');
    const values = columns.map((c) => c.value).join('\t');

    const dummy = document.createElement('textarea');
    dummy.value = `${headers}\n${values}`;
    document.body.appendChild(dummy);
    dummy.select();
    document.execCommand('copy');
    dummy.parentElement.removeChild(dummy);
  }

  drawInputList(headers, values) {
    headers.unshift('Excelの行番号');
    const columns = headers.map((header, index) => {
      return {
        id: String(index),
        label: header,
        style: {width: 100, minWidth: 100}
      };
    });

    const records = values.map((value, index) => {
      const lineNo = index + 2;
      const result = {'0': lineNo};
      value.forEach((v, colIndex) => {
        result[String(colIndex + 1)] = v;
      });
      return result;
    });

    this.setState({
      records: records,
      columns: columns,
      showInput: true,
      canExecute: true,
    });
  }

  save() {
    const action = this.decideAction(this.state.actionType);
    this.setState({showProgress: true});

    action.then((res) => {
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({
          showDialog: false,
          canExecute: false,
          errorMessage: ''
        });
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({
        errorMessage: errorMessage,
        canExecute: false,
      });
      console.error(errorMessage);
    });
  }

  decideAction(actionType) {
    const params = {
      accountId: sessionState.accountId(),
      farmId: sessionState.currentFarmId(),
      rows: this.rows,
      notes: this.state.notes
    };

    switch (actionType) {
    case 'insert': {
      this.notification = '登録が完了しました';
      return cowEventImportAPI.create(params);
    }
    case 'update': {
      this.notification = '更新が完了しました';
      return cowEventImportAPI.update(params);
    }
    case 'delete': {
      this.notification = '削除が完了しました';
      return cowEventImportAPI.delete(params);
    }
    default:
      throw new Error(`不正な値 actionType: ${actionType}`);
    }
  }

  createParams() {
    return {
      accountId: sessionState.accountId(),
      farmId: sessionState.currentFarmId(),
      rows: this.rows,
      notes: this.state.notes
    };
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>イベント一括編集</h2>

        <ul className={classes.flexContainer}>
          <li style={{marginRight: 50}}>
            <FarmSelect changeAction={() => this.setEventTypes()} />
          </li>

          <li style={{marginRight: 30}}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.pasteAction()}
            >
              貼付
            </Button>
          </li>

          <li style={{marginRight: 30}}>
            <InputLabel htmlFor="action-type" style={{fontSize: 12}}>編集内容</InputLabel>
            <Select
              className={classes.selectField}
              value={this.state.actionType}
              onChange={(e) => this.onChangeInput(e, 'actionType')}
              inputProps={{name: 'actionType', id: 'action-type'}}
            >
              <MenuItem value={'insert'}>登録</MenuItem>
              <MenuItem value={'update'}>更新</MenuItem>
              <MenuItem value={'delete'}>削除</MenuItem>
            </Select>
          </li>

          <li>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.save()}
              disabled={!this.state.canExecute}
            >
              実行
            </Button>
          </li>
        </ul>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <ul className={classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
          <li style={{width: 50, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>備考</InputLabel>
          </li>

          <li style={{marginRight: 30}}>
            <TextField
              type="text" margin="dense" style={{width: 620}}
              value={this.state.notes}
              variant="outlined" multiline={true} rows={2}
              onChange={(e) => this.onChangeInput(e, 'notes')}
            />
          </li>

          <li style={{width: 350}}>
            <div className={classes.enclosedArea} style={{marginTop: 10}}>
              <div style={{marginBottom: 10, fontStyle: 'italic', color: '#696969'}}>
                入力サンプル
              </div>

              <InputLabel htmlFor="action-type" style={{fontSize: 12, marginLeft: 10}}>イベント種別</InputLabel>
              <Select
                value={this.state.eventType}
                onChange={(e) => this.onChangeInput(e, 'eventType')}
                inputProps={{name: 'eventType', id: 'event-type'}}
                style={{width: 200, marginLeft: 10, marginRight: 20}}
              >
                {this.state.eventTypes.map((e) => (
                  <MenuItem key={e.eventType} value={e.eventType}>{e.label}</MenuItem>
                ))}
              </Select>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.copyInputSample()}
              >
                コピー
              </Button>

              <input type="text" id="dummy-input" style={{visibility: 'hidden'}} />
            </div>
          </li>
        </ul>

        <div style={{marginLeft: 20}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        {this.state.showInput ?
          <SimpleTable
            records={this.state.records}
            columns={this.state.columns}
            height={400}
          />
          : null
        }
      </div>
    );
  }
}

CowEventImportIndex.propTypes = {
  classes: PropTypes.object.isRequired,
};

const forExport = withStyles(styles)(CowEventImportIndex);
export {forExport as CowEventImportIndex};
