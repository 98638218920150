import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';

import {SessionContext} from 'session/session-context.js';
import {sessionState} from 'session/session-state.js';
import {sessionAPI} from 'api/session.js';
import {farmAPI} from 'api/farm.js';
import {Farm} from 'models/farm.js';
import {ErrorUtil} from 'utils/error-util.js';

import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'blue',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
});

class LoginForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      email: '',
      password: '',
      errorMessage: '',
      showProgress: false,
    };

    this.originPath = props.originPath;
    this.syncState = props.syncState;
  }

  componentDidMount() {
    if (sessionState.authenticated) {
      this.setState({showProgress: true});
      this.setGlobalState();
    }
  }

  componentWillUnmount() {
    this.setState({showProgress: false});
  }

  onChangeInput(e, key) {
    this.setState({[key]: e.target.value});
  }

  onClickLogin() {
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    if (email && password) {
      this.login(email, password);
    } else {
      this.setState({errorMessage: 'メールアドレスとパスワードを入力してください'});
    }
  }

  login(email, password) {
    const params = {
      email: email,
      password: password
    };

    this.setState({showProgress: true});

    sessionAPI.create(params).then((res) => {
      sessionState.login(res.data);
      this.setGlobalState();
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.syncState('errorMessage', errorMessage);
      console.error(errorMessage);
    });
  }

  setGlobalState() {
    farmAPI.digest().then((res) => {
      const farms = res.data.map((r) => new Farm(r));
      sessionState.store('farms', farms);

      this.syncState('authenticated', true);
      this.props.history.push(this.originPath);
    });
  }

  render() {
    const classes = this.props.classes;
    const env = process.env.REACT_APP_ENVIRONMENT || 'local';

    return (
      <SessionContext.Consumer>
        {(context) => (
          <div>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {env}環境
                </Typography>
                <form className={classes.form}>
                  <TextField
                    type="email"
                    required={true}
                    placeholder="user01@example.com"
                    label="メールアドレス"
                    value={this.state.email}
                    onChange={(e) => this.onChangeInput(e, 'email')}
                    autoFocus
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                  />
                  <TextField
                    type="password"
                    required={true}
                    label="パスワード"
                    value={this.state.password}
                    onChange={(e) => this.onChangeInput(e, 'password')}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <ErrorMessage errorMessage={this.state.errorMessage} />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.onClickLogin(context.login)}
                    disabled={this.state.showProgress}
                    fullWidth
                    className={classes.submit}
                  >
                    ログイン
                  </Button>
                  {this.state.showProgress ? <LinearProgress /> : null}
                </form>
              </div>
            </Container>
          </div>
        )}
      </SessionContext.Consumer>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  originPath: PropTypes.string.isRequired,
  syncState: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(LoginForm));
export {forExport as LoginForm};
