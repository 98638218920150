import * as React from 'react';
import {useState} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {sessionState} from 'session/session-state.js';
import {DateUtil} from 'utils/date-util.js';
import {FarmSelect} from 'components/FarmSelect.js';
import {GlobalCss} from 'components/global-css.js';
import {DatePickerWrapper} from 'components/DatePickerWrapper.js';
import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

const EartagAnalyzerIndex = (props) => {
  const [selectedFarm, setSelectedFarm] = useState(sessionState.currentFarm());
  const [connectionId, setConnectionId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startHour, setStartHour] = useState('');
  const [endHour, setEndHour] = useState('');
  const [enableMultipleDays, setEnableMultipleDays] = useState(false);
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const createParams = () => {
    return {
      pathname: '/eartag_analyzer/confirm',
      state: {
        farm: selectedFarm,
        connectionId: connectionId,
        startDate: DateUtil.toMSec(startDate),
        endDate: DateUtil.toMSec(endDate),
        startHour: startHour,
        endHour: endHour,
        notes: notes,
        enableMultipleDays: enableMultipleDays
      }
    };
  };

  const clearDateTimeState = () => {
    setEndDate(null);
    setEndHour(null);
  };

  const createAction = () => {
    const result = validate();
    if (!result.valid) {
      setErrorMessage(result.errors.join('\n'));
      return;
    } else {
      setErrorMessage('');
    }
    props.history.push(createParams());
  };

  const validate = () => {
    const errors = [];
    if (!startDate) {
      errors.push('開始日付が指定されていません。');
    }

    if (startDate) {
      if (DateUtil.isFutureDay(startDate)) {
        errors.push('開始日付は未来の日付を指定できません。');
      }
    }

    if (!startHour) {
      errors.push('開始時間が指定されていません。');
    }

    if (!endHour) {
      errors.push('終了時間が指定されていません。');
    }

    if (enableMultipleDays) {
      if (startDate.getMonth() !== endDate.getMonth()) {
        errors.push('異なる月は指定できません。');
      }

      if (startDate.getYear() !== endDate.getYear()) {
        errors.push('異なる年は指定できません。');
      }

      if (startDate.getDate() > endDate.getDate()) {
        errors.push('開始日付は終了日付より前に設定してください。');
      }

      if (startDate.getDate() === endDate.getDate() && startHour > endHour) {
        errors.push('開始時間は終了時間より前に設定してください。');
      }

      if (endDate) {
        if (DateUtil.isFutureDay(endDate)) {
          errors.push('終了日付は未来の日付を指定できません。');
        }
      }
    }

    if (endDate === null && startHour > endHour) {
      errors.push('開始時間は終了時間より前に設定してください。');
    }

    if (!connectionId) {
      errors.push('コネクションIDが指定されていません。');
    }

    if (notes && notes.length > 180) {
      errors.push('備考は180文字以内に設定してください。');
    }

    return {valid: errors.length === 0, errors: errors};
  };

  const filterFunction = (farms) => {
    return farms.filter((farm) => farm.data.useSensorType !== 'use_neck_sensor');
  };

  return (
    <div style={{marginLeft: '20px'}}>
      <h2>[イヤタグ] 活動量再分析</h2>

      <div style={{marginLeft: '40px'}}>
        <ErrorMessage errorMessage={errorMessage} />
      </div>

      <ul className={props.classes.flexContainer}>
        <li>
          <FarmSelect filterFunction={filterFunction} changeAction={() => {
            setSelectedFarm(sessionState.currentFarm());
          }
          }/>
        </li>

        <li style={{marginLeft: '100px'}}>
          <Button variant="contained" color="primary" onClick={() => createAction()}>
            実行
          </Button>
        </li>
      </ul>
      <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
        <li>
          <TextField
            type="text" margin="none"
            label="コネクションID"
            value={connectionId}
            onChange={(e) => setConnectionId(e.target.value)}
          />
        </li>
      </ul>
      <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
        <li>
          <FormControlLabel
            control={
              <Checkbox
                checked={enableMultipleDays}
                onChange={() => {
                  clearDateTimeState();
                  setEnableMultipleDays(!enableMultipleDays);
                }}
                color="primary"
              />
            }
            label="複数日付を分析対象にする"
          />
        </li>
      </ul>
      <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
        <li style={{width: 150, marginRight: 10}}>
          <DatePickerWrapper
            label={'開始日付'}
            date={startDate}
            margin="none"
            syncState={(e) => setStartDate(DateUtil.startOfDay(e))}
          />
        </li>
        <li>
          <InputLabel htmlFor="cow-label" style={{fontSize: 12}}>開始時間</InputLabel>
          <Select
            style={{marginTop: 5, width: 100}}
            value={startHour}
            onChange={(e) => setStartHour(e.target.value)}
            inputProps={{name: 'inputPath', id: 'input-path'}}
          >
            {(() => {
              const list = [];
              for (let index = 0; index < 24; index++) {
                const hour = ('00' + index).slice(-2);
                list.push(<MenuItem key={`start-hour-${index}`} value={hour}>{hour}:00</MenuItem>);
              }
              return list;
            })()}
          </Select>
        </li>
        <li><div style={{width: 50, textAlign: 'center', marginTop: 20}}>  ~ </div></li>
        {enableMultipleDays &&
          <li style={{width: 150, marginRight: 10}}>
            <DatePickerWrapper
              label="終了日付"
              date={endDate}
              margin="none"
              syncState={(e) => setEndDate(DateUtil.startOfDay(e))}
            />
          </li>
        }
        <li>
          <InputLabel htmlFor="cow-label" style={{fontSize: 12}}>終了時間</InputLabel>
          <Select
            style={{marginTop: 5, width: 100}}
            value={endHour}
            onChange={(e) => setEndHour(e.target.value)}
            inputProps={{name: 'inputPath', id: 'input-path'}}
          >
            {(() => {
              const list = [];
              for (let index = 0; index < 24; index++) {
                const hour = ('000' + index).slice(-2);
                list.push(<MenuItem key={`end-hour-${index}`} value={hour}>{hour}:50</MenuItem>);
              }
              return list;
            })()}
          </Select>
        </li>
      </ul>

      <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
        <li>
          <TextField
            type="text"
            label="備考"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            variant="outlined"
            margin="dense"
            fullWidth={true}
            className={props.classes.textAreaField}
            multiline
            rows={5}
            style={{width: 500}}
          />
        </li>
      </ul>
    </div>
  );
};

EartagAnalyzerIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(EartagAnalyzerIndex));
export {forExport as EartagAnalyzerIndex};
