export class Account {
  static availableRoles(farm) {
    if (farm.isAdminFarm()) {
      return ['operator', 'owner', 'organizer', 'partner', 'supervisor', 'admin'];
    } else if (farm.isDepositor()) {
      return ['operator', 'owner', 'organizer', 'partner'];
    }
    return ['operator', 'owner', 'partner'];
  }
}
