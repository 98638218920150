class SessionState {
  constructor() {
    this.session = {currentFarmId: 1};
    this.authenticated = false;
    this.cache = {};

    this.sessionKey = process.env.REACT_APP_SESSION_KEY;
    const session = JSON.parse(localStorage.getItem(this.sessionKey));
    if (session) {
      this.session = session;
      this.authenticated = true;
    }
  }

  login(session) {
    this.session.sessionToken = session.sessionToken;
    this.session.refreshToken = session.refreshToken;
    this.session.expiredAt = session.expiredAt;
    this.session.accountId = session.accountId;

    localStorage.setItem(this.sessionKey, JSON.stringify(this.session));
    this.authenticated = true;
  }

  logout() {
    localStorage.removeItem(this.sessionKey);
    this.session = {};
    this.authenticated = false;
    window.location.href = '/login';
  }

  sessionToken() {
    return this.session.sessionToken;
  }

  refreshToken() {
    return this.session.refreshToken;
  }

  expiredAt() {
    return this.session.expiredAt;
  }

  accountId() {
    return this.session.accountId;
  }

  changeFarmId(farmId) {
    this.session.currentFarmId = farmId;
    localStorage.setItem(this.sessionKey, JSON.stringify(this.session));
  }

  currentFarmId() {
    return this.session.currentFarmId;
  }

  currentFarm() {
    if (!this.session.currentFarmId) return null;
    return this.cache.farms.find((farm) => farm.id() === this.session.currentFarmId);
  }

  store(id, value) {
    this.cache[id] = value;
  }

  farms() {
    return this.cache.farms;
  }
}

export const sessionState = new SessionState();
