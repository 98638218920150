import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';


const useTableHeadStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    zIndex: 0
  }
}));

const SelectionTableHead = (props) => {
  const classes = useTableHeadStyles();
  const {columns, numSelected, rowCount, onSelectAllClick} = props;

  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all rows'}}
          />
        </TableCell>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

SelectionTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  title: {
    flex: '1 1 100%',
  },
}));

const SelectionTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {numSelected, title} = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} 件選択
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
    </Toolbar>
  );
};

SelectionTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
    position: 'relative',
    zIndex: 0
  },
  tableBody: {
    position: 'relative',
    zIndex: -1
  }
}));

export default function SelectionTable(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const {columns, height, rows, title, onSelect} = props;

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      onSelect(newSelecteds);
    } else {
      setSelected([]);
      onSelect([]);
    }
  };

  const onClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    onSelect(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <SelectionTableToolbar numSelected={selected.length} title={title} />
      <div style={{height, overflow: 'scroll', marginBottom: '30px'}}>
        <Table
          className={classes.table}
          aria-labelledby="title"
          size={'small'}
          aria-label="selection table"
        >
          <SelectionTableHead
            columns={columns}
            numSelected={selected.length}
            onSelectAllClick={onSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody className={classes.tableBody}>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `selection-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => onClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{'aria-labelledby': labelId}}
                      color="primary"
                    />
                  </TableCell>
                  {columns.map((c) => {
                    return (
                      <TableCell key={c.id}>{row[c.id]}</TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

SelectionTable.defaultProps = {
  height: 400,
  rows: [],
  title: ''
};

SelectionTable.propTypes = {
  columns: PropTypes.array.isRequired,
  height: PropTypes.number,
  rows: PropTypes.array,
  title: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};
