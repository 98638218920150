import * as React from 'react';
import {useState} from 'react';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {EartagAnalyzerIndex} from './EartagAnalyzerIndex';
import {sessionState} from 'session/session-state.js';
import {eartagAnalyzerAPI} from 'api/eartag-analyzer.js';
import {DateUtil} from 'utils/date-util.js';
import {ErrorUtil} from 'utils/error-util.js';

import {GlobalCss} from 'components/global-css.js';
import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

const EartagAnalyzerConfirm = (props) => {
  const [showProgress, setShowProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const initialState = props.location.state;

  const returnAction = () => {
    const params = {
      pathname: '/eartag_analyzer',
    };
    props.history.push(params);
  };

  if (!initialState) {
    returnAction();
    return <EartagAnalyzerIndex/>;
  }

  const createParams = () => {
    return {
      'accountId': sessionState.accountId(),
      'farmId': initialState.farm.data.id,
      'connectionId': initialState.connectionId,
      'startDate': initialState.startDate,
      'endDate': initialState.endDate,
      'startHour': Number(initialState.startHour),
      'endHour': Number(initialState.endHour),
      'notes': initialState.notes
    };
  };

  const executeApi = () => {
    const action = eartagAnalyzerAPI.retry(createParams());

    setShowProgress(true);
    action.catch((error) => {
      setShowProgress(false);
      const errorMessage = ErrorUtil.generateMessage(error);
      setErrorMessage(errorMessage);
      console.error(errorMessage);
    });

    setShowDialog(true);
    setTimeout(() => {
      setShowProgress(false);
      returnAction();
    }, 2000);
  };

  return (
    <div style={{marginLeft: '20px'}}>
      <h3>以下の内容で再分析を実行しますか？</h3>

      <Box style={{marginLeft: '40px'}}>
        <ErrorMessage errorMessage={errorMessage} />
      </Box>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        TransitionComponent={Slide}
        aria-labelledby="notification-dialog"
      >
        <DialogTitle id="notification-dialog">Lamdaで分析が開始されます。</DialogTitle>
      </Dialog>

      <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
        {showProgress ? <LinearProgress /> : null}
      </div>

      <div style={{marginLeft: '30px'}}>
        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 130, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>牧場名</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={initialState.farm.data.farmName}
              disabled={true}
              margin="none"
              style={{width: 300}}
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 130, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>farm_id</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={initialState.farm.data.id}
              disabled={true}
              margin="none"
              style={{width: 300}}
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 130, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>コネクションID</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={initialState.connectionId}
              disabled={true}
              margin="none"
              style={{width: 300}}
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 130, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>開始日付</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={DateUtil.toW3CFormat(initialState.startDate)}
              disabled={true}
              margin="none"
              style={{width: 120}}
            />
          </li>
          <li style={{paddingTop: 5, marginLeft: 20}}>
            <InputLabel htmlFor="cow-label" style={{fontSize: 12}}>開始時間</InputLabel>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={`${initialState.startHour}:00`}
              disabled={true}
              margin="none"
              style={{width: 120}}
            />
          </li>
          <li><div style={{width: 50, textAlign: 'center', marginTop: 20}}>  ~ </div></li>
          {initialState.enableMultipleDay && <>
              <li style={{width: 130, marginTop: 10}} >
                <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>終了日付</InputLabel>
              </li>
              <li style={{paddingTop: 5}}>
                <TextField
                  type="text"
                  required={true}
                  placeholder=""
                  value={DateUtil.toW3CFormat(initialState.endDate)}
                  disabled={true}
                  margin="none"
                  style={{width: 120}}
                />
              </li>
            </>
          }

          <li style={{paddingTop: 5, marginLeft: 20}}>
            <InputLabel htmlFor="cow-label" style={{fontSize: 12}}>終了時間</InputLabel>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={`${initialState.endHour}:50`}
              disabled={true}
              margin="none"
              style={{width: 120}}
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer}>
          <li style={{width: 50, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>備考:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              value={initialState.notes}
              variant="outlined"
              disabled={true}
              margin="dense"
              fullWidth={true}
              className={props.classes.textAreaField}
              multiline
              rows={5}
              style={{width: 500}}
            />
          </li>
        </ul>

        <div style={{marginTop: '50px', marginLeft: '50px'}}>
          <ul className={props.classes.flexContainer}>
            <li style={{marginRight: '30px'}}>
              <Button style={{width: '100px'}} variant="contained" color="primary" onClick={() => executeApi()}>
                実行
              </Button>
            </li>
            <li>
              <Button variant="contained" color="secondary" onClick={() => returnAction()}>
                  キャンセル
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

EartagAnalyzerConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(EartagAnalyzerConfirm));
export {forExport as EartagAnalyzerConfirm};
