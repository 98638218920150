import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import {notificationAPI} from 'api/notification.js';
import {DateUtil} from 'utils/date-util.js';
import {ViewMode} from 'models/view-mode.js';
import {Notification} from 'models/notification';
import {ActionTable} from 'components/tables/ActionTable.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

class NotificationIndex extends React.Component {
  constructor() {
    super();

    const columns = [
      {id: 'id', label: 'お知らせID'},
      {id: 'farmTypeDisplay', label: '牧場種別'},
      {id: 'contractedPlan', label: '契約プラン'},
      {id: 'startAtDisplay', label: '表示開始時刻'},
      {id: 'endAtDisplay', label: '表示終了時刻'},
      {id: 'title', label: 'タイトル'},
      {id: 'categoryDisplay', label: 'カテゴリー'},
    ];

    this.state = {
      records: [],
      columns: columns,
      showProgress: false
    };
  }

  componentDidMount() {
    this.index();
  }

  createAction() {
    const params = {
      pathname: '/notification/create',
    };
    this.props.history.push(params);
  }

  updateAction(record) {
    const params = {
      pathname: `/notification/${record.id}/${ViewMode.UPDATE}`,
    };
    this.props.history.push(params);
  }

  deleteAction(record) {
    const params = {
      pathname: `/notification/${record.id}/${ViewMode.DELETE}`,
    };
    this.props.history.push(params);
  }

  index() {
    this.setState({showProgress: true});

    return notificationAPI.index().then((res) => {
      const records = res.data.map((r) => {
        r.farmTypeDisplay = Notification.farmTypes.get(r.farmType);
        r.startAtDisplay = DateUtil.toYYYYMMDDHHmm(r.startAt);
        r.endAtDisplay = DateUtil.toYYYYMMDDHHmm(r.endAt);
        r.categoryDisplay = Notification.categories.get(r.category);
        r.canUpdate = true;
        r.canDelete = true;
        return r;
      });

      this.setState(() => ({
        records: records,
        showProgress: false
      }));
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <Box style={{marginLeft: 20}}>
        <h2>お知らせ</h2>

        <List className={classes.flexContainer}>
          <ListItem></ListItem>
          <ListItem>
            <Button variant="contained" color="primary" onClick={() => this.createAction()}>
              登録
            </Button>
          </ListItem>
        </List>

        <Box style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </Box>

        <ActionTable
          records={this.state.records}
          columns={this.state.columns}
          updateAction={(record) => this.updateAction(record)}
          deleteAction={(record) => this.deleteAction(record)}
        />
      </Box>
    );
  }
}

NotificationIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(NotificationIndex));
export {forExport as NotificationIndex};
